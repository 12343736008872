import { useState, useEffect } from 'react';
import { 
  GoogleAuthProvider,
  signInWithPopup,
  signOut as firebaseSignOut,
  onAuthStateChanged
} from 'firebase/auth';
import { ref, set, get } from 'firebase/database';
import { auth, db } from '../config';

export const useAuth = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        // Kullanıcı bilgilerini veritabanına kaydet/güncelle
        const userRef = ref(db, `users/${currentUser.uid}`);
        const snapshot = await get(userRef);
        
        if (!snapshot.exists()) {
          // Yeni kullanıcı, veritabanına ekle
          await set(userRef, {
            email: currentUser.email,
            displayName: currentUser.displayName,
            photoURL: currentUser.photoURL,
            role: null, // Başlangıçta rol yok
            createdAt: new Date().toISOString(),
            lastLogin: new Date().toISOString()
          });
        } else {
          // Mevcut kullanıcı, son giriş zamanını güncelle
          const userData = snapshot.val();
          await set(userRef, {
            ...userData,
            email: currentUser.email, // Email güncellenmiş olabilir
            displayName: currentUser.displayName, // İsim güncellenmiş olabilir
            photoURL: currentUser.photoURL, // Profil fotoğrafı güncellenmiş olabilir
            lastLogin: new Date().toISOString()
          });
        }

        // Kullanıcı bilgilerini state'e kaydet
        const updatedSnapshot = await get(userRef);
        setUser({ ...currentUser, ...updatedSnapshot.val() });
      } else {
        setUser(null);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const loginWithGoogle = async () => {
    try {
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);
      return result.user;
    } catch (err) {
      setError(err.message);
      throw err;
    }
  };

  const signOut = async () => {
    try {
      await firebaseSignOut(auth);
      setUser(null);
    } catch (err) {
      setError(err.message);
      throw err;
    }
  };

  return { user, loading, error, loginWithGoogle, signOut };
}; 