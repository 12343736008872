import { useEffect, useState } from 'react';
import { useDatabase } from '../firebase/hooks/useDatabase';

const APP_VERSION = '1.0.1'; // Statik uygulama versiyonu

export const useVersionCheck = () => {
  const versionDB = useDatabase('version');
  const [needsUpdate, setNeedsUpdate] = useState(false);

  useEffect(() => {
    if (versionDB.data) {
      const serverVersion = versionDB.data.current;
      if (serverVersion && serverVersion !== APP_VERSION) {
        setNeedsUpdate(true);
        // Kullanıcıya uyarı göster ve sayfayı yenile
        alert('Uygulamanın yeni bir sürümü mevcut. Sayfa yenilenecek.');
        window.location.reload();
      }
    }
  }, [versionDB.data]); // versionDB.data değiştiğinde otomatik olarak kontrol edilecek

  return needsUpdate;
}; 