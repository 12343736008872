import { useState, useEffect } from 'react';
import { 
  ref,
  set,
  get,
  update,
  remove,
  onValue,
  push,
  child
} from 'firebase/database';
import { db } from '../config';

export const useDatabase = (path) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Realtime veri dinleme
  useEffect(() => {
    const dbRef = ref(db, path);
    const unsubscribe = onValue(dbRef, (snapshot) => {
      setData(snapshot.val());
      setLoading(false);
    }, (error) => {
      setError(error);
      setLoading(false);
    });

    return () => unsubscribe();
  }, [path]);

  // Veri ekleme
  const addData = async (newData, customPath = '') => {
    try {
      if (customPath) {
        const dbRef = ref(db, `${path}/${customPath}`);
        await set(dbRef, newData);
        return customPath;
      } else {
        const dbRef = ref(db, path);
        const newRef = push(dbRef);
        await set(newRef, newData);
        return newRef.key;
      }
    } catch (err) {
      setError(err.message);
      throw err;
    }
  };

  // Veri güncelleme
  const updateData = async (id, updates) => {
    try {
      const dbRef = ref(db, `${path}/${id}`);
      await update(dbRef, updates);
    } catch (err) {
      setError(err.message);
      throw err;
    }
  };

  // Veri silme
  const deleteData = async (id) => {
    try {
      const dbRef = ref(db, `${path}/${id}`);
      await remove(dbRef);
    } catch (err) {
      setError(err.message);
      throw err;
    }
  };

  // Tek seferlik veri okuma
  const getData = async (childPath = '') => {
    try {
      const dbRef = ref(db, path);
      const snapshot = await get(child(dbRef, childPath));
      return snapshot.val();
    } catch (err) {
      setError(err.message);
      throw err;
    }
  };

  return {
    data,
    loading,
    error,
    addData,
    updateData,
    deleteData,
    getData
  };
}; 