import React, { useState, useEffect } from 'react';
import {
  Box,
  Paper,
  Typography,
  Chip,
  IconButton,
  Tooltip,
  CircularProgress,
  Divider,
  Modal,
  Button,
  List,
  ListItem,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import {
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
  Visibility as VisibilityIcon
} from '@mui/icons-material';
import { DataGrid } from '@mui/x-data-grid';
import { useDatabase } from '../firebase/hooks/useDatabase';
import { useTheme } from '@mui/material/styles';

const Logs = () => {
  const [rows, setRows] = useState([]);
  const [expandedRow, setExpandedRow] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [dateFilter, setDateFilter] = useState('daily');
  const [loading, setLoading] = useState(true);
  const paymentsDB = useDatabase('payments');
  const theme = useTheme();

  // Tarih filtreleme fonksiyonu
  const isDateInRange = (date, time) => {
    const [day, month, year] = date.split('-').map(Number);
    const [hour, minute] = time.split(':').map(Number);

    // Gün dönümü ayarlaması (01:00)
    let recordDate = new Date(year, month - 1, day, hour, minute);
    if (hour < 1) {
      recordDate = new Date(year, month - 1, day + 1, hour, minute);
    }

    // Şu anki tarihi al ve gün başlangıcını 01:00 olarak ayarla
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 1, 0, 0);

    switch (dateFilter) {
      case 'daily': {
        // Bugün 01:00'dan yarın 01:00'a kadar
        const tomorrow = new Date(today);
        tomorrow.setDate(tomorrow.getDate() + 1);
        return recordDate >= today && recordDate < tomorrow;
      }

      case 'weekly': {
        // Bu hafta Pazartesi 01:00'dan gelecek Pazartesi 01:00'a kadar
        const monday = new Date(today);
        monday.setDate(monday.getDate() - monday.getDay() + (monday.getDay() === 0 ? -6 : 1));
        const nextMonday = new Date(monday);
        nextMonday.setDate(monday.getDate() + 7);
        return recordDate >= monday && recordDate < nextMonday;
      }

      case 'monthly': {
        // Bu ay 1'i 01:00'dan gelecek ay 1'i 01:00'a kadar
        const firstDay = new Date(today.getFullYear(), today.getMonth(), 1, 1);
        const firstDayNextMonth = new Date(today.getFullYear(), today.getMonth() + 1, 1, 1);
        return recordDate >= firstDay && recordDate < firstDayNextMonth;
      }

      case 'yearly': {
        // Bu yıl 1 Ocak 01:00'dan gelecek yıl 1 Ocak 01:00'a kadar
        const firstDayOfYear = new Date(today.getFullYear(), 0, 1, 1);
        const firstDayOfNextYear = new Date(today.getFullYear() + 1, 0, 1, 1);
        return recordDate >= firstDayOfYear && recordDate < firstDayOfNextYear;
      }

      default:
        return true;
    }
  };

  useEffect(() => {
    if (paymentsDB.data) {
      try {
        const processedRows = [];

        Object.entries(paymentsDB.data).forEach(([date, timeLogs]) => {
          if (typeof timeLogs === 'object' && timeLogs !== null) {
            Object.entries(timeLogs).forEach(([time, logs]) => {
              if (typeof logs === 'object' && logs !== null && isDateInRange(date, time)) {
                Object.entries(logs).forEach(([id, log]) => {
                  if (id.startsWith('closing')) {
                    const totalPaidAmount = parseFloat(log.totalPaidAmount) || 0;
                    const totalDiscountAmount = parseFloat(log.totalDiscountAmount) || 0;

                    console.log('Masa Kapanış Tutarları:', {
                      id,
                      tableName: log.tableName,
                      totalPaidAmount,
                      totalDiscountAmount,
                      rawLog: log
                    });

                    const row = {
                      id: `${date}_${time}_${id}`,
                      date,
                      time,
                      type: 'Masa Kapanış',
                      tableName: log.tableName || 'Bilinmiyor',
                      cashier: log.cashier?.name || 'Bilinmiyor',
                      paymentMethods: Array.isArray(log.payments)
                        ? [...new Set(log.payments.map(p => p.method === 'cash' ? 'Nakit' : 'Kredi Kartı'))].join(', ')
                        : '',
                      totalAmount: totalPaidAmount + totalDiscountAmount,
                      discount: totalDiscountAmount,
                      netAmount: totalPaidAmount,
                      details: {
                        ...log,
                        type: 'Masa Kapanış',
                        orders: log.orders || [],
                        payments: Array.isArray(log.payments) ? log.payments.map(payment => ({
                          ...payment,
                          time: time,
                          discountAmount: parseFloat(payment.discountAmount) || 0,
                          discountPercentage: payment.discountPercentage || 0,
                          amount: parseFloat(payment.amount) || 0
                        })) : [],
                        time: time
                      }
                    };
                    processedRows.push(row);
                  } else if (id.startsWith('payment_')) {
                    const amount = parseFloat(log.amount) || 0;
                    const discountAmount = parseFloat(log.discountAmount) || 0;

                    console.log('Ödeme Tutarları:', {
                      amount,
                      discountAmount
                    });

                    const row = {
                      id: `${date}_${time}_${id}`,
                      date,
                      time,
                      type: 'Ödeme',
                      tableName: log.tableName || 'Bilinmiyor',
                      cashier: log.cashier?.name || 'Bilinmiyor',
                      paymentMethods: log.method === 'cash' ? 'Nakit' : 'Kredi Kartı',
                      totalAmount: amount + discountAmount,
                      discount: discountAmount,
                      netAmount: amount,
                      details: {
                        ...log,
                        type: 'Ödeme',
                        amount: amount,
                        discountAmount: discountAmount,
                        time: time
                      }
                    };
                    processedRows.push(row);
                  }
                });
              }
            });
          }
        });

        // Tarihe ve saate göre sıralama
        processedRows.sort((a, b) => {
          // Önce tarihleri karşılaştır
          const dateA = a.date.split('-').reverse().join('-'); // DD-MM-YYYY -> YYYY-MM-DD
          const dateB = b.date.split('-').reverse().join('-');

          if (dateA !== dateB) {
            return dateB.localeCompare(dateA); // Yeni tarihler önce
          }

          // Tarihler aynıysa saatleri karşılaştır
          return b.time.localeCompare(a.time); // Geç saatler önce
        });

        setRows([...processedRows]);
      } catch (error) {
        console.error('Veri işleme hatası:', error);
      } finally {
        setLoading(false);
      }
    }
  }, [paymentsDB.data, dateFilter]);

  const formatPrice = (price) => {
    if (typeof price !== 'number') return '0.00 ₺';
    return `${price.toFixed(2)} ₺`;
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedRow(null);
  };

  const columns = [
    {
      field: 'actions',
      headerName: '',
      width: 50,
      sortable: false,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        if (params.row.type !== 'Masa Kapanış') return null;

        return (
          <Box sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer'
          }}>
            <IconButton
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                setSelectedRow(params.row);
                setModalOpen(true);
              }}
            >
              <VisibilityIcon fontSize="small" />
            </IconButton>
          </Box>
        );
      }
    },
    {
      field: 'date',
      headerName: 'Tarih',
      width: 120,
      align: 'center',
      headerAlign: 'center'
    },
    {
      field: 'time',
      headerName: 'Saat',
      width: 100,
      align: 'center',
      headerAlign: 'center'
    },
    {
      field: 'type',
      headerName: 'İşlem',
      width: 130,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => (
        <Box sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
          <Chip
            label={params.value}
            color={params.value === 'Masa Kapanış' ? 'success' : 'primary'}
            size="small"
          />
        </Box>
      )
    },
    {
      field: 'tableName',
      headerName: 'Masa',
      width: 100,
      align: 'center',
      headerAlign: 'center'
    },
    {
      field: 'totalAmount',
      headerName: 'Toplam',
      width: 120,
      align: 'right',
      headerAlign: 'right',
      renderCell: (params) => (
        <Typography>
          {formatPrice(params.row.totalAmount)}
        </Typography>
      )
    },
    {
      field: 'discount',
      headerName: 'İndirim',
      width: 120,
      align: 'right',
      headerAlign: 'right',
      renderCell: (params) => (
        <Typography color="error">
          {formatPrice(params.row.discount)}
        </Typography>
      )
    },
    {
      field: 'netAmount',
      headerName: 'Net Tutar',
      width: 120,
      align: 'right',
      headerAlign: 'right',
      renderCell: (params) => (
        <Typography>
          {formatPrice(params.row.netAmount)}
        </Typography>
      )
    },
    {
      field: 'cashier',
      headerName: 'Kasiyer',
      width: 130,
      align: 'center',
      headerAlign: 'center'
    },
    {
      field: 'paymentMethods',
      headerName: 'Ödeme Tipleri',
      width: 150,
      align: 'center',
      headerAlign: 'center'
    }
  ];

  const renderExpandedRow = (details) => {
    if (!details || !details.orders) return null;

    return (
      <Box sx={{ p: 2 }}>
        {details.orders.map((order, index) => (
          <Box key={index} sx={{ mb: 2 }}>
            <Typography variant="subtitle2" sx={{ mb: 1, color: 'text.secondary' }}>
              Sipariş Alan: {order.waiter?.name || 'Bilinmiyor'}
            </Typography>
            <List dense>
              {order.items.map((item, itemIndex) => (
                <ListItem key={itemIndex}>
                  <ListItemText
                    primary={
                      <Box component="span">
                        {`${item.name} x${item.quantity}`}
                        {item.complimentaryQuantity > 0 && ` (${item.complimentaryQuantity} İkram)`}
                      </Box>
                    }
                    secondary={
                      <>
                        {Object.entries(item.selectedOptions || {}).map(([optionId, selected]) => (
                          Array.isArray(selected) ? selected.join(', ') : selected
                        )).filter(Boolean).join(', ')}
                        {item.note && (
                          <Typography variant="caption" display="block">
                            Not: {item.note}
                          </Typography>
                        )}
                      </>
                    }
                  />
                  <Typography variant="body2" color="primary">
                    {((item.total / item.quantity) * (item.quantity - (item.complimentaryQuantity || 0))).toFixed(2)} ₺
                  </Typography>
                </ListItem>
              ))}
            </List>
            <Divider sx={{ my: 1 }} />
          </Box>
        ))}
        <Typography variant="subtitle2" sx={{ mt: 2 }}>
          Toplam Tutar: {details.totalAmount.toFixed(2)} ₺
        </Typography>
      </Box>
    );
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ height: '100%', width: '100%', p: 2 }}>
      <Typography variant="h5" gutterBottom>
        Ödeme Kayıtları
      </Typography>

      {/* Filtreleme Butonları */}
      <Box sx={{ mb: 3, display: 'flex', gap: 2 }}>
        <Button
          variant={dateFilter === 'daily' ? 'contained' : 'outlined'}
          onClick={() => setDateFilter('daily')}
        >
          Günlük
        </Button>
        <Button
          variant={dateFilter === 'weekly' ? 'contained' : 'outlined'}
          onClick={() => setDateFilter('weekly')}
        >
          Haftalık
        </Button>
        <Button
          variant={dateFilter === 'monthly' ? 'contained' : 'outlined'}
          onClick={() => setDateFilter('monthly')}
        >
          Aylık
        </Button>
        <Button
          variant={dateFilter === 'yearly' ? 'contained' : 'outlined'}
          onClick={() => setDateFilter('yearly')}
        >
          Yıllık
        </Button>
      </Box>

      <Box sx={{ display: 'flex', gap: 2, height: 'calc(100vh - 200px)' }}>
        {/* Ana Tablo */}
        <Paper sx={{
          flex: 3,
          display: 'flex',
          flexDirection: 'column'
        }}>
          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={25}
            rowsPerPageOptions={[25]}
            disableSelectionOnClick
            getRowId={(row) => row.id}
            sx={{
              flexGrow: 1,
              '& .MuiDataGrid-cell': {
                borderBottom: 'none',
                padding: '8px'
              },
              '& .MuiDataGrid-row': {
                borderBottom: 1,
                borderColor: 'divider',
                '&:hover': {
                  backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.08)' : 'rgba(0, 0, 0, 0.04)'
                }
              },
              '& ::-webkit-scrollbar': {
                width: '8px',
                height: '8px'
              },
              '& ::-webkit-scrollbar-track': {
                background: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.05)',
                borderRadius: '4px'
              },
              '& ::-webkit-scrollbar-thumb': {
                background: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.2)' : 'rgba(0, 0, 0, 0.2)',
                borderRadius: '4px',
                '&:hover': {
                  background: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.3)' : 'rgba(0, 0, 0, 0.3)'
                }
              }
            }}
          />
        </Paper>

        {/* Özet Tablosu */}
        <Paper sx={{ 
          flex: 1, 
          p: 2,
          display: 'flex',
          flexDirection: 'column'
        }}>
          <Typography variant="h6" gutterBottom>
            Özet Bilgiler
          </Typography>
          
          <TableContainer>
            <Table size="small">
              <TableBody>
                <TableRow>
                  <TableCell>Toplam Tutar</TableCell>
                  <TableCell align="right">
                    {formatPrice(rows.filter(row => row.type === 'Masa Kapanış').reduce((sum, row) => sum + row.totalAmount, 0))}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Toplam İndirim</TableCell>
                  <TableCell align="right" sx={{ color: 'error.main' }}>
                    {formatPrice(rows.filter(row => row.type === 'Masa Kapanış').reduce((sum, row) => sum + row.discount, 0))}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Toplam Net Tutar</TableCell>
                  <TableCell align="right" sx={{ fontWeight: 'bold' }}>
                    {formatPrice(rows.filter(row => row.type === 'Masa Kapanış').reduce((sum, row) => sum + row.netAmount, 0))}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell colSpan={2}>
                    <Divider sx={{ my: 1 }} />
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>Toplam Kredi Kartı</TableCell>
                  <TableCell align="right">
                    {formatPrice(rows.reduce((sum, row) => 
                      sum + (row.paymentMethods.includes('Kredi Kartı') ? row.totalAmount : 0), 0))}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Kredi Kartı İndirim</TableCell>
                  <TableCell align="right" sx={{ color: 'error.main' }}>
                    {formatPrice(rows.reduce((sum, row) => 
                      sum + (row.paymentMethods.includes('Kredi Kartı') ? row.discount : 0), 0))}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Net Kredi Kartı</TableCell>
                  <TableCell align="right" sx={{ fontWeight: 'bold' }}>
                    {formatPrice(rows.reduce((sum, row) => 
                      sum + (row.paymentMethods.includes('Kredi Kartı') ? row.netAmount : 0), 0))}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell colSpan={2}>
                    <Divider sx={{ my: 1 }} />
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>Toplam Nakit</TableCell>
                  <TableCell align="right">
                    {formatPrice(rows.reduce((sum, row) => 
                      sum + (row.paymentMethods.includes('Nakit') ? row.totalAmount : 0), 0))}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Nakit İndirim</TableCell>
                  <TableCell align="right" sx={{ color: 'error.main' }}>
                    {formatPrice(rows.reduce((sum, row) => 
                      sum + (row.paymentMethods.includes('Nakit') ? row.discount : 0), 0))}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Net Nakit</TableCell>
                  <TableCell align="right" sx={{ fontWeight: 'bold' }}>
                    {formatPrice(rows.reduce((sum, row) => 
                      sum + (row.paymentMethods.includes('Nakit') ? row.netAmount : 0), 0))}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>

      {/* Detay Modal */}
      <Modal
        open={modalOpen}
        onClose={handleCloseModal}
        aria-labelledby="payment-details-modal"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '80%',
          maxWidth: 800,
          maxHeight: '90vh',
          bgcolor: 'background.paper',
          borderRadius: 1,
          boxShadow: 24,
          p: 4,
          overflow: 'auto',
          '&::-webkit-scrollbar': {
            width: '8px',
            height: '8px',
          },
          '&::-webkit-scrollbar-track': {
            background: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.05)',
            borderRadius: '4px',
          },
          '&::-webkit-scrollbar-thumb': {
            background: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.2)' : 'rgba(0, 0, 0, 0.2)',
            borderRadius: '4px',
            '&:hover': {
              background: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.3)' : 'rgba(0, 0, 0, 0.3)',
            }
          }
        }}>
          {selectedRow?.type === 'Masa Kapanış' && (
            <>
              <Typography variant="h6" component="h2" gutterBottom>
                Masa {selectedRow.tableName} - Kapanış Detayları
              </Typography>

              <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 'bold', color: 'primary.main', mt: 3 }}>
                Sipariş Detayları
              </Typography>

              {renderExpandedRow(selectedRow.details)}

              <Divider sx={{ my: 2 }} />

              {/* Ödemeler */}
              <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 'bold', color: 'primary.main' }}>
                Ödeme Detayları
              </Typography>

              <Box sx={{ pl: 2 }}>
                {/* Ödeme Özetleri */}
                <TableContainer component={Paper} variant="outlined" sx={{ mb: 3 }}>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Saat</TableCell>
                        <TableCell>Ödeme Tipi</TableCell>
                        <TableCell align="right">Tutar</TableCell>
                        <TableCell align="right">İndirim</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {/* Tüm ödemeler */}
                      {selectedRow.details.payments?.map((payment, index) => (
                        <TableRow key={`payment_${index}`}>
                          <TableCell>{payment.time || '-'}</TableCell>
                          <TableCell>
                            {payment.method === 'cash' ? 'Nakit' : 'Kredi Kartı'}
                          </TableCell>
                          <TableCell align="right">{formatPrice(payment.amount)}</TableCell>
                          <TableCell align="right">
                            {payment.discountAmount > 0 ? (
                              <>
                                {formatPrice(payment.discountAmount)}
                                <Typography variant="caption" color="error.main" sx={{ ml: 0.5 }}>
                                  (%{payment.discountPercentage})
                                </Typography>
                              </>
                            ) : '-'}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>

                {/* Toplam Bilgileri */}
                <Box sx={{
                  mt: 2,
                  pt: 2,
                  borderTop: 1,
                  borderColor: 'divider'
                }}>
                  <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mb: 1
                  }}>
                    <Typography variant="subtitle1">
                      Toplam Tutar:
                    </Typography>
                    <Typography variant="h6">
                      {formatPrice(selectedRow.totalAmount)}
                    </Typography>
                  </Box>

                  <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mb: 1
                  }}>
                    <Typography variant="subtitle1" color="error">
                      Toplam İndirim:
                    </Typography>
                    <Typography variant="h6" color="error">
                      -{formatPrice(selectedRow.discount)}
                    </Typography>
                  </Box>

                  <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mt: 2,
                    pt: 2,
                    borderTop: 1,
                    borderColor: 'divider'
                  }}>
                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                      Net Tutar:
                    </Typography>
                    <Typography variant="h6" color="primary.main" sx={{ fontWeight: 'bold' }}>
                      {formatPrice(selectedRow.netAmount)}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </>
          )}

          <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
            <Button onClick={handleCloseModal} variant="contained">
              Kapat
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default Logs; 