import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database';
import { getAuth } from 'firebase/auth';

// Firebase yapılandırma bilgileri
const firebaseConfig = {
    apiKey: "AIzaSyBNjnpfa4R6l33FInTZQmu6wgJCex3OPtI",
    authDomain: "lerascoffee.firebaseapp.com",
    databaseURL: "https://lerascoffee-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "lerascoffee",
    storageBucket: "lerascoffee.firebasestorage.app",
    messagingSenderId: "92832825724",
    appId: "1:92832825724:web:303f692676ffcae4e4e4f9",
    measurementId: "G-GWPP3W5FNR"
};

// Firebase'i başlat
const app = initializeApp(firebaseConfig);

// Auth ve Database servislerini al
const auth = getAuth(app);
const db = getDatabase(app);

export { db, auth };
export default app; 