import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Grid,
  Paper,
  Typography,
  Box,
  useTheme,
} from '@mui/material';
import { useDatabase } from '../firebase/hooks/useDatabase';

const Home = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const tablesDB = useDatabase('tables');
  const ordersDB = useDatabase('orders');
  const [activeTableOrders, setActiveTableOrders] = useState({});

  useEffect(() => {
    if (ordersDB.data) {
      // Her masanın siparişlerini bul (durumuna bakılmaksızın)
      const tableOrders = {};
      Object.entries(ordersDB.data).forEach(([_, order]) => {
        tableOrders[order.tableId] = true;
      });
      setActiveTableOrders(tableOrders);
    }
  }, [ordersDB.data]);

  if (tablesDB.loading || ordersDB.loading) {
    return <Typography>Yükleniyor...</Typography>;
  }

  const tables = tablesDB.data ? Object.entries(tablesDB.data).map(([id, data]) => ({
    ...data,
    id,
    isOccupied: activeTableOrders[id] || false
  })) : [];

  return (
    <Box sx={{ 
      p: 3,
      height: 'calc(100vh - 80px)',
      overflow: 'auto',
      '&::-webkit-scrollbar': {
        width: '8px',
      },
      '&::-webkit-scrollbar-track': {
        backgroundColor: theme => theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.05)',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: theme => theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.15)' : 'rgba(0, 0, 0, 0.15)',
        borderRadius: '4px',
        '&:hover': {
          backgroundColor: theme => theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.25)' : 'rgba(0, 0, 0, 0.25)',
        }
      }
    }}>
      <Typography variant="h5" gutterBottom>
        Masalar
      </Typography>
      <Grid container spacing={2} justifyContent="center">
        {tables.map((table) => (
          <Grid item xs={6} sm={4} md={2} key={table.id}>
            <Paper
              elevation={3}
              sx={{
                p: 2,
                cursor: 'pointer',
                borderLeft: 5,
                borderColor: table.isOccupied ? 'error.main' : 'success.main',
                bgcolor: theme.palette.background.paper,
                transition: 'all 0.2s ease-in-out',
                '&:hover': {
                  bgcolor: theme.palette.mode === 'dark' 
                    ? theme.palette.action.hover
                    : theme.palette.grey[50],
                  transform: 'scale(1.02)'
                }
              }}
              onClick={() => navigate(`/table/${table.id}`)}
            >
              <Typography variant="h6">{table.name}</Typography>
              <Typography variant="body2" color="text.secondary">
                {table.isOccupied ? 'Dolu' : 'Boş'}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {table.type === 'large' ? '4 Kişilik' : '2 Kişilik'}
              </Typography>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Home; 