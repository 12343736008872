import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import {
  Grid,
  Paper,
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
  Button,
  Divider,
  Card,
  CardContent,
  ToggleButton,
  ToggleButtonGroup,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Chip,
} from '@mui/material';
import {
  Backspace as BackspaceIcon,
  CardGiftcard as CardGiftcardIcon,
  Percent as PercentIcon,
  Close as CloseIcon,
  Remove as RemoveIcon,
  Add as AddIcon,
  CompareArrows as CompareArrowsIcon,
} from '@mui/icons-material';
import { useDatabase } from '../firebase/hooks/useDatabase';
import { useAuth } from '../firebase/hooks/useAuth';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

const NumericKeypad = ({ onNumberClick, onBackspace, onClear }) => {
  const numbers = ['1', '2', '3', '4', '5', '6', '7', '8', '9', 'C', '0', '⌫'];

  return (
    <Grid container spacing={1}>
      {numbers.map((num) => (
        <Grid item xs={4} key={num}>
          <Button
            fullWidth
            variant="outlined"
            onClick={() => {
              if (num === '⌫') onBackspace();
              else if (num === 'C') onClear();
              else onNumberClick(num);
            }}
            sx={{
              height: '70px',
              fontSize: '1.5rem'
            }}
          >
            {num}
          </Button>
        </Grid>
      ))}
    </Grid>
  );
};

const DiscountDialog = ({ open, onClose, amount, onApplyDiscount }) => {
  const [discountType, setDiscountType] = useState('amount');
  const [discountValue, setDiscountValue] = useState('');
  const [error, setError] = useState('');

  const handleDiscountTypeChange = (event) => {
    setDiscountType(event.target.value);
    setDiscountValue('');
    setError('');
  };

  const handleValueChange = (event) => {
    const value = event.target.value;
    if (value === '' || /^\d*\.?\d*$/.test(value)) {
      setDiscountValue(value);
      setError('');
    }
  };

  const handleApply = () => {
    const numValue = parseFloat(discountValue);
    if (!numValue || numValue <= 0) {
      setError('Geçerli bir tutar giriniz');
      return;
    }

    if (discountType === 'percent') {
      if (numValue > 100) {
        setError('Yüzde 100\'den büyük olamaz');
        return;
      }
      const discountAmount = (amount * numValue) / 100;
      onApplyDiscount(discountAmount, { type: 'percent', value: numValue });
    } else {
      if (numValue > amount) {
        setError('İndirim tutarı girilen tutardan büyük olamaz');
        return;
      }
      onApplyDiscount(numValue, { type: 'amount', value: numValue });
    }
    onClose();
  };

  const handleClose = () => {
    setDiscountValue('');
    setError('');
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
      <DialogTitle>İndirim Uygula</DialogTitle>
      <DialogContent>
        <Box sx={{ pt: 1 }}>
          <FormControl component="fieldset" sx={{ mb: 2 }}>
            <RadioGroup
              row
              value={discountType}
              onChange={handleDiscountTypeChange}
            >
              <FormControlLabel value="amount" control={<Radio />} label="Tutar" />
              <FormControlLabel value="percent" control={<Radio />} label="Yüzde" />
            </RadioGroup>
          </FormControl>
          <TextField
            fullWidth
            label={discountType === 'percent' ? 'Yüzde Değeri' : 'İndirim Tutarı'}
            value={discountValue}
            onChange={handleValueChange}
            error={!!error}
            helperText={error || `Girilen Tutar: ${amount.toFixed(2)} ₺`}
            InputProps={{
              endAdornment: <Typography>{discountType === 'percent' ? '%' : '₺'}</Typography>
            }}
          />
          {discountValue && !error && (
            <Typography variant="body2" color="primary" sx={{ mt: 1 }}>
              İndirim Tutarı: {discountType === 'percent'
                ? `${((amount * parseFloat(discountValue)) / 100).toFixed(2)} ₺`
                : `${parseFloat(discountValue).toFixed(2)} ₺`}
            </Typography>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>İptal</Button>
        <Button onClick={handleApply} variant="contained" disabled={!discountValue || !!error}>
          Uygula
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const ComplimentaryDialog = ({ open, onClose, item, currentComplimentary = 0, onApply }) => {
  const [quantity, setQuantity] = useState(currentComplimentary);

  useEffect(() => {
    setQuantity(currentComplimentary);
  }, [currentComplimentary]);

  const handleQuantityChange = (delta) => {
    const newQuantity = quantity + delta;
    if (newQuantity >= 0 && newQuantity <= (item?.quantity || 0)) {
      setQuantity(newQuantity);
    }
  };

  const handleApply = () => {
    onApply(quantity);
    onClose();
  };

  if (!item) return null;

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle>İkram Miktarı - {item.name}</DialogTitle>
      <DialogContent>
        <Box sx={{ pt: 2 }}>
          <Typography variant="body1" gutterBottom>
            Toplam Miktar: {item.quantity}
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 2, my: 2 }}>
            <IconButton onClick={() => handleQuantityChange(-1)} disabled={quantity <= 0}>
              <RemoveIcon />
            </IconButton>
            <Typography variant="h5">{quantity}</Typography>
            <IconButton onClick={() => handleQuantityChange(1)} disabled={quantity >= item.quantity}>
              <AddIcon />
            </IconButton>
          </Box>
          <Typography variant="body2" color="text.secondary">
            İkram Tutarı: {((item.total / item.quantity) * quantity).toFixed(2)} ₺
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>İptal</Button>
        <Button onClick={handleApply} variant="contained">
          Uygula
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const TableTransferDialog = ({ open, onClose, tables, selectedTable, onTransfer }) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle>Masa Taşıma</DialogTitle>
      <DialogContent>
        <List>
          {tables
            .filter(table => table.id !== selectedTable && !table.isOccupied)
            .map((table) => (
              <ListItem
                key={table.id}
                button
                onClick={() => onTransfer(table.id)}
                sx={{
                  mb: 1,
                  borderRadius: 1,
                  borderLeft: 3,
                  borderColor: 'success.main'
                }}
              >
                <ListItemText
                  primary={table.name}
                  secondary={`${table.type === 'large' ? '4' : '2'} Kişilik`}
                />
              </ListItem>
            ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>İptal</Button>
      </DialogActions>
    </Dialog>
  );
};

const PaymentPanel = forwardRef(({
  totalAmount,
  selectedTable,
  tableName,
  activeOrders,
  setSelectedTable,
  setActiveOrders,
  tablesDB,
  ordersDB,
  paymentsDB,
  user,
  setSelectedOrders,
  setActiveTableOrders
}, ref) => {
  const theme = useTheme();
  const [enteredAmount, setEnteredAmount] = useState('');
  const [changeAmount, setChangeAmount] = useState(0);
  const [paymentMethod, setPaymentMethod] = useState('cash');
  const [remainingAmount, setRemainingAmount] = useState(totalAmount);
  const [selectedDiscount, setSelectedDiscount] = useState(null);
  const [totalDiscountAmount, setTotalDiscountAmount] = useState(0);
  const [currentDiscountAmount, setCurrentDiscountAmount] = useState(0);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const previousAmount = useRef(null);
  const [previousPayments, setPreviousPayments] = useState([]);

  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useImperativeHandle(ref, () => ({
    handleItemClick: (item) => {
      const unitPrice = item.total / item.quantity;
      const paidCount = item.paidCount || 0;

      if (paidCount >= item.quantity) {
        // Eğer tüm ürünler ödendiyse, sayacı sıfırla
        setSelectedOrders(prev => {
          const newOrders = [...prev];
          const orderIndex = newOrders.findIndex(o => o.items.some(i => i === item));
          if (orderIndex !== -1) {
            const itemIndex = newOrders[orderIndex].items.findIndex(i => i === item);
            if (itemIndex !== -1) {
              newOrders[orderIndex].items[itemIndex] = {
                ...item,
                paidCount: 0
              };
            }
          }
          return newOrders;
        });
        const currentAmount = parseFloat(enteredAmount || 0);
        const newAmount = Math.max(0, currentAmount - (unitPrice * item.quantity)).toFixed(2);
        setEnteredAmount(newAmount);
        if (paymentMethod === 'cash') {
          const change = parseFloat(newAmount) - remainingAmount;
          setChangeAmount(change > 0 ? change : 0);
        }
      } else {
        // Bir ürün daha ödenmiş olarak işaretle
        setSelectedOrders(prev => {
          const newOrders = [...prev];
          const orderIndex = newOrders.findIndex(o => o.items.some(i => i === item));
          if (orderIndex !== -1) {
            const itemIndex = newOrders[orderIndex].items.findIndex(i => i === item);
            if (itemIndex !== -1) {
              newOrders[orderIndex].items[itemIndex] = {
                ...item,
                paidCount: paidCount + 1
              };
            }
          }
          return newOrders;
        });
        const currentAmount = parseFloat(enteredAmount || 0);
        const newAmount = (currentAmount + unitPrice).toFixed(2);
        setEnteredAmount(newAmount);
        if (paymentMethod === 'cash') {
          const change = parseFloat(newAmount) - remainingAmount;
          setChangeAmount(change > 0 ? change : 0);
        }
      }
    }
  }));

  const handleFullAmount = () => {
    setEnteredAmount(remainingAmount.toFixed(2));
    setChangeAmount(0);
  };

  const handleHalfAmount = () => {
    setEnteredAmount((remainingAmount / 2).toFixed(2));
    setChangeAmount(0);
  };

  const handleSplitPayment = () => {
    if (remainingAmount > 0 && enteredAmount) {
      const count = parseInt(enteredAmount);
      if (count > 0) {
        const splitAmount = (remainingAmount / count).toFixed(2);
        setEnteredAmount(splitAmount);
      }
    }
  };

  const handleNumberClick = (num) => {
    const newAmount = enteredAmount + num;
    setEnteredAmount(newAmount);
    if (paymentMethod === 'cash') {
      const change = parseFloat(newAmount) - remainingAmount;
      setChangeAmount(change > 0 ? change : 0);
    }
  };

  const handleBackspace = () => {
    const newAmount = enteredAmount.slice(0, -1);
    setEnteredAmount(newAmount);
    if (paymentMethod === 'cash' && newAmount) {
      const change = parseFloat(newAmount) - remainingAmount;
      setChangeAmount(change > 0 ? change : 0);
    } else {
      setChangeAmount(0);
    }
  };

  const handleClear = () => {
    setEnteredAmount('');
    setChangeAmount(0);
  };

  const handleDiscountPercentage = (percentage) => {
    if (!enteredAmount) return;

    if (selectedDiscount === percentage) {
      // Aynı butona tekrar tıklandığında indirimi iptal et
      const originalAmount = previousAmount.current;
      setSelectedDiscount(null);
      setEnteredAmount(originalAmount);
      setCurrentDiscountAmount(0);
      
      // Para üstünü yeniden hesapla
      if (paymentMethod === 'cash' && originalAmount) {
        const change = parseFloat(originalAmount) - remainingAmount;
        setChangeAmount(change > 0 ? change : 0);
      }
      
      previousAmount.current = null;
    } else {
      // Yeni indirim uygula
      if (!previousAmount.current) {
        previousAmount.current = enteredAmount;
      }
      const amount = parseFloat(enteredAmount);
      const discountedAmount = (amount * (100 - percentage) / 100).toFixed(2);
      const discountValue = (amount - parseFloat(discountedAmount)).toFixed(2);
      
      setEnteredAmount(discountedAmount);
      setSelectedDiscount(percentage);
      setCurrentDiscountAmount(parseFloat(discountValue));

      // Para üstünü her zaman hesapla
      const change = parseFloat(discountedAmount) - remainingAmount;
      setChangeAmount(change > 0 ? change : 0);
    }
  };

  useEffect(() => {
    if (selectedTable) {
      const loadTablePayments = async () => {
        try {
          const tableData = await tablesDB.getData(selectedTable);
          const paidAmount = tableData?.paidAmount || 0;
          const discountAmount = tableData?.discountAmount || 0;
          
          setRemainingAmount(totalAmount - paidAmount - discountAmount);
          setTotalDiscountAmount(discountAmount);
        } catch (error) {
          console.error('Masa ödemeleri yüklenirken hata:', error);
        }
      };

      loadTablePayments();
    } else {
      setRemainingAmount(totalAmount);
      setTotalDiscountAmount(0);
    }
  }, [selectedTable, totalAmount, tablesDB]);

  useEffect(() => {
    const loadPreviousPayments = async () => {
      if (!selectedTable) return;

      try {
        // Önce masa verilerini al
        const tableData = await tablesDB.getData(selectedTable);
        const lastClosingTime = tableData?.lastClosingTime;

        const today = new Date();
        const dateKey = today.toLocaleDateString('tr-TR', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric'
        }).replace(/\./g, '-');

        const paymentsData = await paymentsDB.getData(`${dateKey}`);
        if (!paymentsData) return;

        let allPayments = Object.entries(paymentsData)
          .flatMap(([_, timeData]) => 
            Object.entries(timeData).map(([_, payment]) => payment)
          )
          .filter(payment => {
            // Sadece bu masaya ait ve kapanış olmayan ödemeleri al
            const isThisTable = payment.tableId === selectedTable;
            const isNotClosing = !payment.closing;
            // Son kapanma zamanından sonraki ödemeleri al
            const isAfterLastClosing = !lastClosingTime || new Date(payment.timestamp) > new Date(lastClosingTime);
            return isThisTable && isNotClosing && isAfterLastClosing;
          })
          .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));

        setPreviousPayments(allPayments);

        // Toplam ödenmiş tutarı ve indirimi hesapla
        const totalPaid = allPayments.reduce((sum, payment) => 
          sum + (payment.amount - (payment.change || 0)), 0
        );
        const totalDiscount = allPayments.reduce((sum, payment) => 
          sum + (payment.discountAmount || 0), 0
        );

        // State'leri güncelle
        setRemainingAmount(totalAmount - totalPaid - totalDiscount);
        setTotalDiscountAmount(totalDiscount);
        
      } catch (error) {
        console.error('Önceki ödemeler yüklenirken hata:', error);
      }
    };

    loadPreviousPayments();
  }, [selectedTable, paymentsDB, totalAmount, tablesDB]);

  const handlePayment = async (method) => {
    try {
      const paymentAmount = parseFloat(enteredAmount);
      if (isNaN(paymentAmount) || paymentAmount <= 0) {
        console.error('Geçersiz ödeme tutarı');
        return;
      }

      const now = new Date();
      const dateKey = now.toLocaleDateString('tr-TR', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
      }).replace(/\./g, '-');

      const timeKey = now.toLocaleTimeString('tr-TR', {
        hour: '2-digit',
        minute: '2-digit'
      });

      const uniqueId = `payment_${Date.now()}_${Math.random().toString(36).substr(2, 9)}`;

      // Ödeme kaydını oluştur
      const paymentData = {
        method,
        amount: paymentAmount,
        timestamp: now.toISOString(),
        change: method === 'cash' ? changeAmount : 0,
        tableId: selectedTable,
        tableName: tableName,
        discountAmount: selectedDiscount ? currentDiscountAmount : 0,
        discountPercentage: selectedDiscount,
        cashier: {
          id: user.uid,
          name: user.displayName || user.email
        }
      };

      // Ödeme kaydını Firebase'e ekle
      await paymentsDB.addData(paymentData, `${dateKey}/${timeKey}/${uniqueId}`);

      // Önceki ödemelere ekle
      setPreviousPayments(prev => [...prev, paymentData]);

      // Masa verilerini güncelle
      const tableData = await tablesDB.getData(selectedTable);
      const currentPaidAmount = tableData?.paidAmount || 0;
      const currentDiscountTotal = tableData?.discountAmount || 0;
      const newPaidAmount = currentPaidAmount + paymentAmount;
      const newDiscountTotal = currentDiscountTotal + (selectedDiscount ? currentDiscountAmount : 0);
      
      // Eğer kalan tutar 0 veya daha az ise masayı sıfırla
      const effectivePayment = paymentAmount + (selectedDiscount ? currentDiscountAmount : 0);
      if (remainingAmount - effectivePayment <= 0) {
        // Kapanış kaydı oluştur
        const closingData = {
          timestamp: now.toISOString(),
          tableId: selectedTable,
          tableName: tableName,
          totalAmount: totalAmount,
          totalPaidAmount: newPaidAmount,
          totalDiscountAmount: newDiscountTotal,
          discountPercentage: selectedDiscount,
          cashier: {
            id: user.uid,
            name: user.displayName || user.email
          },
          orders: activeOrders.map(order => ({
            ...order,
            items: order.items.map(item => ({
              name: item.name,
              quantity: item.quantity,
              price: item.price,
              total: item.total,
              selectedOptions: item.selectedOptionDetails || {},
              note: item.note || '',
              complimentaryQuantity: item.complimentaryQuantity || 0
            }))
          })),
          closing: true
        };

        await paymentsDB.addData(closingData, `${dateKey}/${timeKey}/closing_${uniqueId}`);

        // Siparişleri arşivle
        for (const order of activeOrders) {
          await ordersDB.deleteData(order.id);
        }

        // Masa verilerini güncelle
        await tablesDB.updateData(selectedTable, {
          status: 'empty',
          paidAmount: 0,
          discountAmount: 0,
          lastPaymentTime: null,
          lastClosingTime: now.toISOString()
        });

        // State'leri temizle
        setSelectedTable(null);
        setSelectedOrders([]);
        setActiveOrders([]);
        setEnteredAmount('');
        setChangeAmount(0);
        setTotalDiscountAmount(0);
        setCurrentDiscountAmount(0);
        setSelectedDiscount(null);
        previousAmount.current = null;
        setPaymentMethod('cash');

        // Kapanan masanın siparişlerini activeTableOrders'dan kaldır
        setActiveTableOrders(prev => {
          const newOrders = { ...prev };
          delete newOrders[selectedTable];
          return newOrders;
        });
      } else {
        // Masayı güncelle
        await tablesDB.updateData(selectedTable, {
          status: 'occupied',
          paidAmount: newPaidAmount,
          discountAmount: newDiscountTotal,
          lastPaymentTime: now.toISOString()
        });

        // State'leri güncelle
        setEnteredAmount('');
        setChangeAmount(0);
        setSelectedDiscount(null);
        previousAmount.current = null;
        setPaymentMethod('cash');

        // Ödemeleri ve state'leri yeniden yükle
        const loadPayments = async () => {
          try {
            // Önce masa verilerini al
            const tableData = await tablesDB.getData(selectedTable);
            const lastClosingTime = tableData?.lastClosingTime;

            const today = new Date();
            const dateKey = today.toLocaleDateString('tr-TR', {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric'
            }).replace(/\./g, '-');

            const paymentsData = await paymentsDB.getData(`${dateKey}`);
            if (!paymentsData) return;

            let allPayments = Object.entries(paymentsData)
              .flatMap(([_, timeData]) => 
                Object.entries(timeData).map(([_, payment]) => payment)
              )
              .filter(payment => {
                const isThisTable = payment.tableId === selectedTable;
                const isNotClosing = !payment.closing;
                const isAfterLastClosing = !lastClosingTime || new Date(payment.timestamp) > new Date(lastClosingTime);
                return isThisTable && isNotClosing && isAfterLastClosing;
              })
              .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));

            setPreviousPayments(allPayments);

            // Toplam ödenmiş tutarı ve indirimi hesapla
            const totalPaid = allPayments.reduce((sum, payment) => 
              sum + (payment.amount - (payment.change || 0)), 0
            );
            const totalDiscount = allPayments.reduce((sum, payment) => 
              sum + (payment.discountAmount || 0), 0
            );

            // State'leri güncelle
            setRemainingAmount(totalAmount - totalPaid - totalDiscount);
            setTotalDiscountAmount(totalDiscount);
          } catch (error) {
            console.error('Ödemeler yüklenirken hata:', error);
          }
        };

        loadPayments();
      }

    } catch (error) {
      console.error('Ödeme işlemi sırasında hata:', error);
    }
  };

  const getDisplayAmount = () => {
    if (!enteredAmount) return (
      <Box sx={{ display: 'flex', alignItems: 'center', whiteSpace: 'nowrap', fontSize: '1.5rem' }}>
        0,00 ₺
      </Box>
    );
    
    const amount = parseFloat(enteredAmount);
    
    if (selectedDiscount) {
      const discountedAmount = amount;
      const originalAmount = (discountedAmount * 100) / (100 - selectedDiscount);
      const discountAmount = originalAmount - discountedAmount;
      
      return (
        <Box sx={{ display: 'flex', alignItems: 'center', whiteSpace: 'nowrap', fontSize: '1.5rem' }}>
          {discountedAmount.toFixed(2)} ₺
          <Typography component="span" color="error.main" sx={{ ml: 1, fontSize: '1.5rem' }}>
            (-{discountAmount.toFixed(2)} ₺)
          </Typography>
        </Box>
      );
    }
    
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', whiteSpace: 'nowrap', fontSize: '1.5rem' }}>
        {amount.toFixed(2)} ₺
      </Box>
    );
  };

  return (
    <Box>
      <Box sx={{ 
        display: 'flex', 
        flexDirection: 'column', 
        height: { xs: `${windowHeight * 0.45}px`, md: 'calc(100vh - 200px)' }, 
        justifyContent: 'space-between' 
      }}>
        <Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, mb: 2 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography variant="subtitle1">Toplam Tutar:</Typography>
              <Typography variant="subtitle1">{totalAmount.toFixed(2)} ₺</Typography>
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography variant="subtitle1">Kalan Tutar:</Typography>
              <Typography variant="subtitle1">{remainingAmount.toFixed(2)} ₺</Typography>
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography variant="subtitle1">Ödenen:</Typography>
              <Typography variant="subtitle1">
                {((totalAmount - remainingAmount) || 0).toFixed(2)} ₺
              </Typography>
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography variant="subtitle1">Toplam İndirim:</Typography>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="subtitle1" color="error" sx={{ mr: 1 }}>
                  {totalDiscountAmount > 0 ? `${totalDiscountAmount.toFixed(2)} ₺` : '0.00 ₺'}
                </Typography>
              </Box>
            </Box>

            <Box sx={{ height: 40 }}>
              {changeAmount > 0 && (
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography variant="h6" color="error">Para Üstü:</Typography>
                  <Typography variant="h6" color="error">{changeAmount.toFixed(2)} ₺</Typography>
                </Box>
              )}
            </Box>

            <TextField
              fullWidth
              value=""
              size="small"
              sx={{
                '& input': {
                  textAlign: 'right',
                  fontSize: '1.5rem',
                  padding: '12px'
                }
              }}
              InputProps={{
                readOnly: true,
                endAdornment: getDisplayAmount()
              }}
            />
          </Box>

          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0 }}>
            <Grid container spacing={1}>
              <Grid item xs={4}>
                <Button
                  fullWidth
                  variant="outlined"
                  onClick={handleFullAmount}
                  disabled={remainingAmount <= 0}
                  sx={{ height: '50px', fontSize: '1.1rem' }}
                >
                  Tamamı
                </Button>
              </Grid>
              <Grid item xs={4}>
                <Button
                  fullWidth
                  variant="outlined"
                  onClick={handleHalfAmount}
                  disabled={remainingAmount <= 0}
                  sx={{ height: '50px', fontSize: '1.1rem' }}
                >
                  Yarısı
                </Button>
              </Grid>
              <Grid item xs={4}>
                <Button
                  fullWidth
                  variant="outlined"
                  onClick={handleSplitPayment}
                  disabled={!enteredAmount}
                  sx={{ height: '50px', fontSize: '1.1rem' }}
                >
                  1/n
                </Button>
              </Grid>
            </Grid>

            <Box sx={{ display: 'flex', gap: 1, mt: 1 }}>
              <Box sx={{ flex: 1 }}>
                <NumericKeypad
                  onNumberClick={handleNumberClick}
                  onBackspace={handleBackspace}
                  onClear={handleClear}
                />

                <Box sx={{ mt: 1 }}>
                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      <Button
                        fullWidth
                        variant="contained"
                        onClick={() => handlePayment('cash')}
                        disabled={!enteredAmount || parseFloat(enteredAmount) <= 0}
                        sx={{ height: '60px', fontSize: '1.2rem' }}
                      >
                        Nakit
                      </Button>
                    </Grid>
                    <Grid item xs={6}>
                      <Button
                        fullWidth
                        variant="contained"
                        onClick={() => handlePayment('card')}
                        disabled={!enteredAmount || parseFloat(enteredAmount) <= 0}
                        sx={{ height: '60px', fontSize: '1.2rem' }}
                      >
                        K. Kartı
                      </Button>
                    </Grid>
                  </Grid>
                </Box>

                {previousPayments.length > 0 && (
                  <Box sx={{ mt: 2, maxHeight: 200, overflow: 'auto' }}>
                    <Typography variant="subtitle2" gutterBottom>Önceki Ödemeler:</Typography>
                    <List dense>
                      {previousPayments.map((payment, index) => (
                        <ListItem key={index}>
                          <ListItemText
                            primary={
                              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Typography variant="body2">
                                  {(payment.amount - (payment.change || 0)).toFixed(2)} ₺ - {payment.method === 'cash' ? 'Nakit' : 'Kredi Kartı'}
                                </Typography>
                                {payment.discountAmount > 0 && !payment.closing && (
                                  <Typography variant="body2" color="error">
                                    İndirim: {payment.discountAmount.toFixed(2)} ₺ ({payment.discountPercentage}%)
                                  </Typography>
                                )}
                              </Box>
                            }
                            secondary={new Date(payment.timestamp).toLocaleTimeString('tr-TR')}
                          />
                        </ListItem>
                      ))}
                    </List>
                  </Box>
                )}
              </Box>

              {paymentMethod === 'cash' && (
                <Box sx={{
                  width: '60px',
                  ml: 2,
                  borderRadius: 1
                }}>
                  <Grid container spacing={1} direction="column">
                    {[5, 10, 20, 30].map((percentage) => (
                      <Grid item xs={12} key={percentage}>
                        <Button
                          fullWidth
                          variant="outlined"
                          onClick={() => handleDiscountPercentage(percentage)}
                          sx={{
                            height: '70px',
                            fontSize: '1.2rem',
                            bgcolor: selectedDiscount === percentage ? 
                              (theme.palette.mode === 'dark' ? 'primary.dark' : 'primary.light') : 
                              (theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.05)' : 'background.paper'),
                            color: selectedDiscount === percentage ? 'primary.contrastText' : 'inherit',
                            '&:hover': {
                              bgcolor: selectedDiscount === percentage ?
                                (theme.palette.mode === 'dark' ? 'primary.dark' : 'primary.light') :
                                (theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'grey.100')
                            }
                          }}
                        >
                          %{percentage}
                        </Button>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
});

const OrderList = ({ orders, onComplimentaryToggle, onItemClick }) => {
  const [complimentaryDialog, setComplimentaryDialog] = useState({
    open: false,
    item: null,
    orderIndex: -1,
    itemIndex: -1
  });
  const optionsDB = useDatabase('options');

  const handleComplimentaryClick = (orderIndex, itemIndex, item) => {
    setComplimentaryDialog({
      open: true,
      item,
      orderIndex,
      itemIndex,
      currentComplimentary: item.complimentaryQuantity || 0
    });
  };

  const handleComplimentaryApply = (quantity) => {
    const { orderIndex, itemIndex } = complimentaryDialog;
    onComplimentaryToggle(orderIndex, itemIndex, quantity);
    setComplimentaryDialog({ open: false, item: null, orderIndex: -1, itemIndex: -1 });
  };

  return (
    <>
      {orders.map((order, orderIndex) => (
        <Box key={order.id} sx={{ mb: 2 }}>
          <List dense>
            {order.items.map((item, itemIndex) => (
              <ListItem
                key={itemIndex}
                sx={{
                  cursor: 'pointer',
                  '&:hover': {
                    bgcolor: theme => theme.palette.mode === 'dark'
                      ? 'rgba(255, 255, 255, 0.05)'
                      : 'rgba(0, 0, 0, 0.05)'
                  }
                }}
                onClick={() => onItemClick && onItemClick(item)}
                component="div"
                secondaryAction={
                  <IconButton
                    edge="end"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleComplimentaryClick(orderIndex, itemIndex, item);
                    }}
                    color={item.complimentaryQuantity > 0 ? "primary" : "default"}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      {item.complimentaryQuantity > 0 && (
                        <Typography variant="caption" sx={{ mr: 0.5 }}>
                          {item.complimentaryQuantity}
                        </Typography>
                      )}
                      <CardGiftcardIcon />
                    </Box>
                  </IconButton>
                }
              >
                <ListItemText
                  primary={
                    <Box component="span">
                      {`${item.name} x${item.quantity}`}
                      {item.complimentaryQuantity > 0 && ` (${item.complimentaryQuantity} İkram)`}
                      <Chip
                        size="small"
                        label={`Ödenen: ${item.paidCount || 0}/${item.quantity}`}
                        color={item.paidCount >= item.quantity ? "success" : "default"}
                        sx={{ ml: 1, height: 20 }}
                      />
                    </Box>
                  }
                  secondary={
                    <>
                      {Object.entries(item.selectedOptionDetails || {}).map(([optionId, selected], idx) => {
                        const option = optionsDB.data?.[optionId];
                        return option ? (
                          <Typography key={`${optionId}-${idx}`} variant="caption" display="block" color="text.secondary">
                            {`${option.name}: ${Array.isArray(selected) ? selected.join(', ') : selected}`}
                          </Typography>
                        ) : null;
                      })}
                      {item.note && (
                        <Typography variant="caption" display="block">
                          Not: {item.note}
                        </Typography>
                      )}
                    </>
                  }
                />
                <Typography variant="body2" color="primary">
                  {(item.total / item.quantity).toFixed(2)} ₺
                </Typography>
              </ListItem>
            ))}
          </List>
          <Divider sx={{ my: 1 }} />
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="caption" color="text.secondary">
              İkram Tutarı: {order.items.reduce((total, item) => total + (item.complimentaryQuantity ? (item.total / item.quantity) * item.complimentaryQuantity : 0), 0).toFixed(2)} ₺
            </Typography>
            <Typography variant="subtitle1">
              Toplam: {order.items.reduce((total, item) => total + ((item.total / item.quantity) * (item.quantity - (item.complimentaryQuantity || 0))), 0).toFixed(2)} ₺
            </Typography>
          </Box>
        </Box>
      ))}

      <ComplimentaryDialog
        open={complimentaryDialog.open}
        onClose={() => setComplimentaryDialog({ open: false, item: null, orderIndex: -1, itemIndex: -1 })}
        item={complimentaryDialog.item}
        currentComplimentary={complimentaryDialog.item?.complimentaryQuantity || 0}
        onApply={handleComplimentaryApply}
      />
    </>
  );
};

const Kasa = () => {
  const { user } = useAuth();
  const theme = useTheme();
  const [selectedTable, setSelectedTable] = useState(null);
  const [selectedOrders, setSelectedOrders] = useState([]);
  const tablesDB = useDatabase('tables');
  const ordersDB = useDatabase('orders');
  const paymentsDB = useDatabase('payments');
  const [activeTableOrders, setActiveTableOrders] = useState({});
  const paymentPanelRef = useRef(null);
  const [activeOrders, setActiveOrders] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [remainingAmount, setRemainingAmount] = useState(0);
  const [paymentLog, setPaymentLog] = useState({});
  const [transferDialogOpen, setTransferDialogOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (ordersDB.data && tablesDB.data) {
      const orders = Object.entries(ordersDB.data)
        .filter(([_, order]) => order.tableId === selectedTable)
        .map(([id, order]) => ({ id, ...order }));

      setActiveOrders(orders);

      const total = orders.reduce((sum, order) => sum + order.total, 0);
      setTotalAmount(total);
      setRemainingAmount(total - (paymentLog[selectedTable]?.reduce((sum, payment) => sum + payment.amount, 0) || 0));
    }
  }, [ordersDB.data, selectedTable, paymentLog]);

  useEffect(() => {
    if (ordersDB.data) {
      const tableOrders = {};
      Object.entries(ordersDB.data).forEach(([orderId, order]) => {
        if (!tableOrders[order.tableId]) {
          tableOrders[order.tableId] = [];
        }
        tableOrders[order.tableId].push({ ...order, id: orderId });
      });
      setActiveTableOrders(tableOrders);
    }
  }, [ordersDB.data]);

  const handlePaymentComplete = async (paymentLog) => {
    try {
      const batch = {};
      const timestamp = new Date().toISOString();
      const paymentId = `payment_${Date.now()}`;

      const paymentData = {
        tableId: selectedTable,
        tableName: selectedTable.name,
        orders: selectedOrders.map(order => ({
          ...order,
          items: order.items.map(item => ({
            ...item,
            isComplimentary: item.isComplimentary || false
          }))
        })),
        payments: paymentLog,
        total: calculateTotal(selectedOrders),
        timestamp,
        cashier: {
          id: user.uid,
          name: user.displayName || user.email
        }
      };

      batch[`payments/${paymentId}`] = paymentData;

      selectedOrders.forEach(order => {
        batch[`orders/${order.id}`] = {
          ...order,
          status: 'paid',
          paymentId,
          paidAt: timestamp,
          items: order.items.map(item => ({
            ...item,
            isComplimentary: item.isComplimentary || false
          }))
        };
      });

      const remainingOrders = activeTableOrders[selectedTable.id]?.filter(
        order => !selectedOrders.find(selected => selected.id === order.id)
      );

      if (!remainingOrders || remainingOrders.length === 0) {
        batch[`tables/${selectedTable.id}/status`] = 'empty';
      }

      await ordersDB.updateMultiple(batch);

      setSelectedTable(null);
      setSelectedOrders([]);

    } catch (error) {
      console.error('Ödeme işlemi sırasında hata:', error);
    }
  };

  if (tablesDB.loading || ordersDB.loading) {
    return <Typography>Yükleniyor...</Typography>;
  }

  const tables = tablesDB.data ? Object.entries(tablesDB.data).map(([id, data]) => ({
    ...data,
    id,
    isOccupied: activeTableOrders && activeTableOrders[id] && activeTableOrders[id].length > 0
  })).sort((a, b) => {
    if (a.isOccupied && !b.isOccupied) return -1;
    if (!a.isOccupied && b.isOccupied) return 1;
    return parseInt(a.name.replace('Masa ', '')) - parseInt(b.name.replace('Masa ', ''));
  }) : [];

  const handleTableClick = (table) => {
    setSelectedTable({
      id: table.id,
      name: table.name
    });
    setSelectedOrders(activeTableOrders && activeTableOrders[table.id] ? activeTableOrders[table.id] : []);
  };

  const calculateTotal = (orders) => {
    return orders.reduce((total, order) =>
      total + order.items.reduce((orderTotal, item) =>
        orderTotal + ((item.total / item.quantity) * (item.quantity - (item.complimentaryQuantity || 0))), 0
      ), 0);
  };

  const handleComplimentaryToggle = (orderIndex, itemIndex, quantity) => {
    setSelectedOrders(prevOrders => {
      const newOrders = [...prevOrders];
      const order = { ...newOrders[orderIndex] };
      const items = [...order.items];
      const item = { ...items[itemIndex] };

      item.complimentaryQuantity = quantity;
      items[itemIndex] = item;
      order.items = items;
      order.total = items.reduce((total, item) =>
        total + ((item.total / item.quantity) * (item.quantity - (item.complimentaryQuantity || 0))), 0
      );

      newOrders[orderIndex] = order;
      return newOrders;
    });
  };

  const handleTableTransfer = async (targetTableId) => {
    try {
      // Kaynak masanın verilerini al
      const sourceTableData = await tablesDB.getData(selectedTable.id);
      const sourceOrders = ordersDB.data ? Object.entries(ordersDB.data)
        .filter(([_, order]) => order.tableId === selectedTable.id)
        .map(([id, order]) => ({ id, ...order })) : [];

      // Ödemeleri al ve güncelle
      const paymentUpdates = [];
      if (paymentsDB.data) {
        Object.entries(paymentsDB.data).forEach(([date, datePayments]) => {
          if (typeof datePayments === 'object') {
            Object.entries(datePayments).forEach(([time, timePayments]) => {
              if (typeof timePayments === 'object') {
                Object.entries(timePayments).forEach(([id, payment]) => {
                  if (payment.tableId === selectedTable.id) {
                    // Yeni bir kayıt oluştur
                    const updatedPayment = {
                      ...payment,
                      tableId: targetTableId,
                      transferredFrom: selectedTable.id,
                      transferTime: new Date().toISOString()
                    };
                    
                    // Eski kaydı güncelle
                    paymentUpdates.push(
                      paymentsDB.updateData(
                        `${date}/${time}/${id}`,
                        updatedPayment
                      )
                    );
                  }
                });
              }
            });
          }
        });
      }

      // Hedef masanın verilerini al
      const targetTableData = await tablesDB.getData(targetTableId);

      // Kaynak masayı sıfırla
      const now = new Date().toISOString();
      await tablesDB.updateData(selectedTable.id, {
        status: 'empty',
        paidAmount: 0,
        discountAmount: 0,
        lastPaymentTime: null,
        lastPaymentAmount: 0,
        lastClosingTime: now
      });

      // Hedef masayı güncelle
      await tablesDB.updateData(targetTableId, {
        status: 'occupied',
        paidAmount: sourceTableData?.paidAmount || 0,
        discountAmount: sourceTableData?.discountAmount || 0,
        lastPaymentTime: sourceTableData?.lastPaymentTime || null,
        lastPaymentAmount: sourceTableData?.lastPaymentAmount || 0,
        lastClosingTime: sourceTableData?.lastClosingTime || null
      });

      // Siparişleri yeni masaya taşı
      for (const order of sourceOrders) {
        await ordersDB.updateData(order.id, {
          ...order,
          tableId: targetTableId
        });
      }

      // Tüm güncellemeleri bekle
      await Promise.all(paymentUpdates);

      // Dialog'u kapat
      setTransferDialogOpen(false);

      // Yeni masaya geç
      const targetTable = tables.find(table => table.id === targetTableId);
      setSelectedTable({
        id: targetTableId,
        name: targetTable.name
      });
      setSelectedOrders(sourceOrders.map(order => ({ ...order, tableId: targetTableId })));
    } catch (error) {
      console.error('Masa taşıma sırasında hata:', error);
    }
  };

  return (
    <Box sx={{ 
      p: { xs: 1, md: 3 },
      height: '150vh',
      overflow: 'auto'
    }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={3}>
          <Paper sx={{
            p: { xs: 1, md: 2 },
            height: { xs: '200px', md: 'calc(100vh - 150px)' },
            overflow: 'auto',
            '&::-webkit-scrollbar': {
              width: '8px',
            },
            '&::-webkit-scrollbar-track': {
              backgroundColor: theme => theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.05)',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: theme => theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.15)' : 'rgba(0, 0, 0, 0.15)',
              borderRadius: '4px',
              '&:hover': {
                backgroundColor: theme => theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.25)' : 'rgba(0, 0, 0, 0.25)',
              }
            }
          }}>
            <Typography variant="h6" gutterBottom>
              Masalar
            </Typography>
            <List sx={{
              '& .MuiListItem-root': {
                py: { xs: 0.5, md: 1 }
              }
            }}>
              <Grid container spacing={1}>
                {tables.map((table) => (
                  <Grid item xs={6} md={12} key={table.id}>
                    <ListItem
                      button
                      selected={selectedTable?.id === table.id}
                      onClick={() => handleTableClick(table)}
                      sx={{
                        mb: { xs: 0.5, md: 1 },
                        borderRadius: 1,
                        borderLeft: 3,
                        borderColor: table.isOccupied ? 'error.main' : 'success.main',
                        backgroundColor: selectedTable?.id === table.id ? 'action.selected' : 'transparent',
                        height: { xs: '60px', md: 'auto' }
                      }}
                    >
                      <ListItemText
                        primary={table.name}
                        secondary={`${table.isOccupied ? 'Dolu' : 'Boş'} - ${table.type === 'large' ? '4' : '2'} Kişilik`}
                        primaryTypographyProps={{
                          sx: { fontSize: { xs: '0.9rem', md: '1rem' } }
                        }}
                        secondaryTypographyProps={{
                          sx: { fontSize: { xs: '0.75rem', md: '0.875rem' } }
                        }}
                      />
                    </ListItem>
                  </Grid>
                ))}
              </Grid>
            </List>
          </Paper>
        </Grid>

        <Grid item xs={12} md={5}>
          <Paper sx={{
            p: { xs: 1, md: 2 },
            height: { xs: '300px', md: 'calc(100vh - 150px)' },
            overflow: 'auto',
            '&::-webkit-scrollbar': {
              width: '8px',
            },
            '&::-webkit-scrollbar-track': {
              backgroundColor: theme => theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.05)',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: theme => theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.15)' : 'rgba(0, 0, 0, 0.15)',
              borderRadius: '4px',
              '&:hover': {
                backgroundColor: theme => theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.25)' : 'rgba(0, 0, 0, 0.25)',
              }
            }
          }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: { xs: 1, md: 2 } }}>
              <Typography variant="h6" sx={{ fontSize: { xs: '1rem', md: '1.25rem' } }}>
                Siparişler - {selectedTable?.name || 'Masa Seçiniz'}
              </Typography>
              {selectedTable && selectedOrders.length > 0 && (
                <Button
                  variant="outlined"
                  startIcon={<CompareArrowsIcon />}
                  onClick={() => setTransferDialogOpen(true)}
                  size="small"
                  sx={{ fontSize: { xs: '0.75rem', md: '0.875rem' } }}
                >
                  Masa Taşı
                </Button>
              )}
            </Box>
            <OrderList
              orders={selectedOrders}
              onComplimentaryToggle={handleComplimentaryToggle}
              onItemClick={(item) => paymentPanelRef.current?.handleItemClick(item)}
            />
          </Paper>
        </Grid>

        <Grid item xs={12} md={4}>
          <Paper sx={{
            p: { xs: 1, md: 2 },
            height: { xs: 'auto', md: 'calc(100vh - 150px)' },
            overflow: 'auto',
            '&::-webkit-scrollbar': {
              width: '8px',
            },
            '&::-webkit-scrollbar-track': {
              backgroundColor: theme => theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.05)',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: theme => theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.15)' : 'rgba(0, 0, 0, 0.15)',
              borderRadius: '4px',
              '&:hover': {
                backgroundColor: theme => theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.25)' : 'rgba(0, 0, 0, 0.25)',
              }
            }
          }}>
            <Typography variant="h6" gutterBottom sx={{ fontSize: { xs: '1rem', md: '1.25rem' } }}>
              Ödeme
            </Typography>
            {selectedTable && selectedOrders.length > 0 ? (
              <PaymentPanel
                ref={paymentPanelRef}
                totalAmount={calculateTotal(selectedOrders)}
                selectedTable={selectedTable.id}
                tableName={selectedTable.name}
                activeOrders={selectedOrders}
                setSelectedTable={setSelectedTable}
                setActiveOrders={setActiveOrders}
                tablesDB={tablesDB}
                ordersDB={ordersDB}
                paymentsDB={paymentsDB}
                user={user}
                setSelectedOrders={setSelectedOrders}
                setActiveTableOrders={setActiveTableOrders}
              />
            ) : (
              <Typography color="text.secondary" align="center" sx={{ mt: 2, fontSize: { xs: '0.875rem', md: '1rem' } }}>
                Ödeme yapmak için bir masa seçin
              </Typography>
            )}
          </Paper>
        </Grid>
      </Grid>

      <TableTransferDialog
        open={transferDialogOpen}
        onClose={() => setTransferDialogOpen(false)}
        tables={tables}
        selectedTable={selectedTable?.id}
        onTransfer={handleTableTransfer}
      />
    </Box>
  );
};

export default Kasa; 