import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Paper,
  Typography,
  Button,
  Alert,
} from '@mui/material';
import { Google as GoogleIcon } from '@mui/icons-material';
import { useAuth } from '../firebase/hooks/useAuth';

const Login = () => {
  const navigate = useNavigate();
  const { loginWithGoogle } = useAuth();
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const handleGoogleLogin = async () => {
    setError('');
    setLoading(true);

    try {
      await loginWithGoogle();
      navigate('/');
    } catch (err) {
      switch (err.code) {
        case 'auth/popup-closed-by-user':
          setError('Giriş işlemi iptal edildi');
          break;
        case 'auth/cancelled-popup-request':
          setError('Birden fazla giriş penceresi açılamaz');
          break;
        case 'auth/popup-blocked':
          setError('Giriş penceresi engellendi');
          break;
        default:
          setError('Giriş yapılırken bir hata oluştu');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        bgcolor: 'grey.100',
      }}
    >
      <Paper
        elevation={3}
        sx={{
          p: 4,
          width: '100%',
          maxWidth: 400,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography variant="h5" align="center" gutterBottom>
          Lera's Coffee
        </Typography>
        <Typography variant="body2" align="center" color="text.secondary" sx={{ mb: 3 }}>
          Yönetim Paneli
        </Typography>

        {error && (
          <Alert severity="error" sx={{ mb: 2, width: '100%' }}>
            {error}
          </Alert>
        )}

        <Button
          variant="contained"
          size="large"
          startIcon={<GoogleIcon />}
          onClick={handleGoogleLogin}
          disabled={loading}
          sx={{ 
            width: '100%',
            bgcolor: 'common.white',
            color: 'text.primary',
            '&:hover': {
              bgcolor: 'grey.100',
            },
            boxShadow: 1,
          }}
        >
          {loading ? 'Giriş Yapılıyor...' : 'Google ile Giriş Yap'}
        </Button>
      </Paper>
    </Box>
  );
};

export default Login; 