import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { useAuth } from './firebase/hooks/useAuth';
import Login from './pages/Login';
import Home from './pages/Home';
import Kasa from './pages/Kasa';
import Ayarlar from './pages/Ayarlar';
import TableOrder from './pages/TableOrder';
import Barista from './pages/Barista';
import Layout from './components/Layout';
import getTheme from './theme';
import Logs from './pages/Logs';
import { VersionCheckLayout } from './components/VersionCheckLayout';
import { useDatabase } from './firebase/hooks/useDatabase';
import { Box, Typography, Button } from '@mui/material';
import { auth } from './firebase/config';

const App = () => {
  const [mode, setMode] = useState('light');
  const { user, loading } = useAuth();
  const theme = React.useMemo(() => getTheme(mode), [mode]);
  const usersDB = useDatabase('users');

  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
      },
    }),
    [],
  );

  // Oturum ve rol durumu yüklenene kadar bekle
  if (loading || usersDB.loading) {
    return null;
  }

  // Kullanıcı giriş yapmış ama rolü atanmamışsa
  if (user && usersDB.data && !usersDB.data[user.uid]?.role) {
    return (
      <Box sx={{ p: 3, textAlign: 'center' }}>
        <Typography variant="h5" gutterBottom>
          Hesabınız Onay Bekliyor
        </Typography>
        <Typography>
          Hesabınıza henüz bir rol atanmamış. Lütfen yönetici ile iletişime geçin.
        </Typography>
        <Button 
          variant="contained" 
          onClick={() => auth.signOut()}
          sx={{ mt: 2 }}
        >
          Çıkış Yap
        </Button>
      </Box>
    );
  }

  return (
    <Router>
      <VersionCheckLayout>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Routes>
            <Route path="/login" element={!user ? <Login /> : <Navigate to="/" />} />
            <Route
              path="/"
              element={
                user ? (
                  <Layout colorMode={colorMode}>
                    <Home />
                  </Layout>
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              path="/kasa"
              element={
                user ? (
                  <Layout colorMode={colorMode}>
                    <Kasa />
                  </Layout>
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              path="/logs"
              element={
                user ? (
                  <Layout colorMode={colorMode}>
                    <Logs />
                  </Layout>
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              path="/ayarlar"
              element={
                user ? (
                  <Layout colorMode={colorMode}>
                    <Ayarlar />
                  </Layout>
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              path="/table/:tableId"
              element={
                user ? (
                  <Layout colorMode={colorMode}>
                    <TableOrder />
                  </Layout>
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              path="/barista"
              element={
                user ? (
                  <Layout colorMode={colorMode}>
                    <Barista />
                  </Layout>
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
          </Routes>
        </ThemeProvider>
      </VersionCheckLayout>
    </Router>
  );
};

export default App; 