import React, { useState, useEffect } from 'react';
import {
  Box,
  Paper,
  Typography,
  Grid,
  IconButton,
  Divider,
  List,
  ListItem,
  ListItemText,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material';
import { Check as CheckIcon } from '@mui/icons-material';
import { useDatabase } from '../firebase/hooks/useDatabase';
import { useTheme } from '@mui/material/styles';

const Barista = () => {
  const [activeOrders, setActiveOrders] = useState([]);
  const [confirmDialog, setConfirmDialog] = useState({
    open: false,
    orderId: null,
    itemIndex: null,
  });
  const ordersDB = useDatabase('orders');
  const tablesDB = useDatabase('tables');
  const theme = useTheme();

  useEffect(() => {
    if (ordersDB.data) {
      const orders = Object.entries(ordersDB.data)
        .filter(([_, order]) => order.status === 'active')
        .map(([id, order]) => ({ id, ...order }))
        .sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));
      setActiveOrders(orders);
    }
  }, [ordersDB.data]);

  const handleCompleteClick = (orderId, itemIndex) => {
    setConfirmDialog({
      open: true,
      orderId,
      itemIndex,
    });
  };

  const handleConfirmComplete = async () => {
    const { orderId, itemIndex } = confirmDialog;
    const order = activeOrders.find(o => o.id === orderId);
    if (!order) return;

    const updatedOrder = { ...order };
    updatedOrder.items[itemIndex].status = 'complete';

    // Tüm ürünler tamamlandıysa siparişin durumunu güncelle
    const allComplete = updatedOrder.items.every(item => item.status === 'complete');
    if (allComplete) {
      updatedOrder.status = 'complete';
    }

    await ordersDB.updateData(orderId, updatedOrder);

    // State'i güncelle
    setActiveOrders(prev => {
      if (allComplete) {
        return prev.filter(o => o.id !== orderId);
      }
      return prev.map(o => o.id === orderId ? updatedOrder : o);
    });

    setConfirmDialog({ open: false, orderId: null, itemIndex: null });
  };

  const handleCancelComplete = () => {
    setConfirmDialog({ open: false, orderId: null, itemIndex: null });
  };

  if (ordersDB.loading || tablesDB.loading) {
    return <Typography>Yükleniyor...</Typography>;
  }

  return (
    <Box sx={{ 
      p: 3,
      height: 'calc(100vh - 80px)',
      overflow: 'auto',
      '&::-webkit-scrollbar': {
        width: '8px',
      },
      '&::-webkit-scrollbar-track': {
        backgroundColor: theme => theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.05)',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: theme => theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.15)' : 'rgba(0, 0, 0, 0.15)',
        borderRadius: '4px',
        '&:hover': {
          backgroundColor: theme => theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.25)' : 'rgba(0, 0, 0, 0.25)',
        }
      }
    }}>
      <Typography variant="h5" gutterBottom>
        Barista Ekranı
      </Typography>

      <Grid container spacing={2}>
        {activeOrders.map((order, index) => (
          <Grid item xs={12} sm={6} md={3} key={order.id}>
            <Paper sx={{ 
              p: 2,
              minHeight: '200px',
              maxHeight: '400px',
              overflow: 'auto',
              bgcolor: theme.palette.mode === 'dark' ? 'grey.800' : 'grey.50',
              '&::-webkit-scrollbar': {
                width: '8px',
              },
              '&::-webkit-scrollbar-track': {
                backgroundColor: theme => theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.05)',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: theme => theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.15)' : 'rgba(0, 0, 0, 0.15)',
                borderRadius: '4px',
                '&:hover': {
                  backgroundColor: theme => theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.25)' : 'rgba(0, 0, 0, 0.25)',
                }
              }
            }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                <Typography variant="h6">
                  Masa: {tablesDB.data?.[order.tableId]?.name || order.tableId}
                </Typography>
                <Box>
                  <Typography variant="caption" color="text.secondary" display="block">
                    Garson: {order.waiter?.name}
                  </Typography>
                  <Typography variant="caption" color="text.secondary">
                    {new Date(order.timestamp).toLocaleTimeString()}
                  </Typography>
                </Box>
              </Box>

              <Divider sx={{ mb: 2 }} />

              <List dense>
                {order.items
                  .filter(item => !item.status || item.status === 'active')
                  .map((item, index) => (
                    <ListItem
                      key={index}
                      secondaryAction={
                        <IconButton
                          edge="end"
                          onClick={() => handleCompleteClick(order.id, index)}
                          sx={{
                            bgcolor: 'success.main',
                            color: 'white',
                            '&:hover': {
                              bgcolor: 'success.dark',
                            },
                          }}
                        >
                          <CheckIcon />
                        </IconButton>
                      }
                    >
                      <ListItemText
                        primary={
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <Typography variant="subtitle1">
                              {item.name} x{item.quantity}
                            </Typography>
                          </Box>
                        }
                        secondary={
                          <>
                            {Object.entries(item.selectedOptionDetails || {}).map(([optionId, selected], idx) => (
                              <Typography key={idx} variant="body2" color="text.secondary">
                                {Array.isArray(selected) ? selected.join(', ') : selected}
                              </Typography>
                            ))}
                            {item.note && (
                              <Typography variant="body2" color="error">
                                Not: {item.note}
                              </Typography>
                            )}
                          </>
                        }
                      />
                    </ListItem>
                  ))}
              </List>
            </Paper>
          </Grid>
        ))}
      </Grid>

      {/* Onay Dialog'u */}
      <Dialog
        open={confirmDialog.open}
        onClose={handleCancelComplete}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle>Ürün Hazır</DialogTitle>
        <DialogContent>
          <Typography>
            Bu ürünü hazır olarak işaretlemek istediğinize emin misiniz?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelComplete}>İptal</Button>
          <Button onClick={handleConfirmComplete} variant="contained" color="success">
            Onayla
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Barista; 