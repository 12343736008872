import { createTheme } from '@mui/material/styles';

const getTheme = (mode) => {
  const coffeeColors = {
    light: {
      primary: {
        main: '#795548',
        light: '#a98274',
        dark: '#4b2c20',
        contrastText: '#ffffff',
      },
      secondary: {
        main: '#d7ccc8',
        light: '#fffffb',
        dark: '#a69b97',
        contrastText: '#000000',
      },
      background: {
        default: '#f5f5f5',
        paper: '#ffffff',
      },
      text: {
        primary: '#3e2723',
        secondary: '#5d4037',
      },
    },
    dark: {
      primary: {
        main: '#795548',
        light: '#a98274',
        dark: '#4b2c20',
        contrastText: '#ffffff',
      },
      secondary: {
        main: '#6d4c41',
        light: '#9c786c',
        dark: '#40241a',
        contrastText: '#ffffff',
      },
      background: {
        default: '#121212',
        paper: '#1e1e1e',
      },
      text: {
        primary: '#efebe9',
        secondary: '#d7ccc8',
      },
    },
  };

  return createTheme({
    palette: {
      mode,
      ...(mode === 'light' ? coffeeColors.light : coffeeColors.dark),
      error: {
        main: '#d32f2f',
      },
      warning: {
        main: '#ffa726',
      },
      info: {
        main: '#29b6f6',
      },
      success: {
        main: '#66bb6a',
      },
    },
    typography: {
      fontFamily: 'Montserrat, sans-serif',
      h1: {
        fontWeight: 600,
      },
      h2: {
        fontWeight: 600,
      },
      h3: {
        fontWeight: 600,
      },
      h4: {
        fontWeight: 600,
      },
      h5: {
        fontWeight: 600,
      },
      h6: {
        fontWeight: 600,
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: 'none',
            fontWeight: 500,
            borderRadius: 8,
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            backgroundImage: 'none',
            borderRadius: 8,
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            borderRadius: 8,
          },
        },
      },
    },
  });
};

export default getTheme;
