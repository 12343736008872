import React, { useState, useEffect } from 'react';
import {
  Box,
  Paper,
  Tabs,
  Tab,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Alert,
  Snackbar,
  IconButton,
  Avatar,
  Chip,
  FormHelperText,
  List,
  ListItem,
  ListItemText
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Upload as UploadIcon
} from '@mui/icons-material';
import { useDatabase } from '../firebase/hooks/useDatabase';
import Draggable from 'react-draggable';
import { importSampleData } from '../utils/importData';
import { importCategoriesFromExcel, importProductsFromExcel } from '../utils/excelImport';
import { getDatabase, ref, onValue, set, remove } from 'firebase/database';
import { getStorage, ref as storageRef, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import { useTheme } from '@mui/material/styles';
import { useAuth } from '../firebase/hooks/useAuth';

const Ayarlar = () => {
  const { user } = useAuth();
  const theme = useTheme();
  const [activeTab, setActiveTab] = useState(0);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogType, setDialogType] = useState('');
  const [editItem, setEditItem] = useState(null);
  const [formData, setFormData] = useState({});
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [imageFile, setImageFile] = useState(null);
  const [imagePreview, setImagePreview] = useState('');

  // Database hooks
  const categoriesDB = useDatabase('categories');
  const productsDB = useDatabase('products');
  const tablesDB = useDatabase('tables');
  const usersDB = useDatabase('users');
  const optionsDB = useDatabase('options');

  // Masa düzeni için state
  const [tables, setTables] = useState([]);
  const [selectedTable, setSelectedTable] = useState(null);

  // Grid boyutlarını tanımla
  const GRID_SIZE = 20; // 20px'lik grid
  const TABLE_BASE_WIDTH = 80; // 2 kişilik masa genişliği
  const TABLE_HEIGHT = 80; // Masa yüksekliği

  // En yakın grid noktasını hesapla
  const snapToGrid = (value) => {
    return Math.round(value / GRID_SIZE) * GRID_SIZE;
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleOpenDialog = (type, item = null) => {
    setDialogType(type);
    setEditItem(item);
    if (type === 'option' && !item) {
      // Yeni seçenek grubu için varsayılan veriler
      setFormData({
        name: '',
        options: [],
        type: 'radio' // radio: tek seçim, checkbox: çoklu seçim
      });
    } else {
      setFormData(item || {});
    }
    setImagePreview(item?.image || '');
    setImageFile(null);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setDialogType('');
    setEditItem(null);
    setFormData({});
    setImagePreview('');
    setImageFile(null);
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const showSnackbar = (message, severity = 'success') => {
    setSnackbar({ open: true, message, severity });
  };

  const handleCloseSnackbar = () => {
    setSnackbar(prev => ({ ...prev, open: false }));
  };

  const handleSave = async () => {
    try {
      switch (dialogType) {
        case 'category':
          if (editItem) {
            await categoriesDB.updateData(editItem.id, formData);
            showSnackbar('Kategori başarıyla güncellendi');
          } else {
            await categoriesDB.addData(formData);
            showSnackbar('Yeni kategori başarıyla eklendi');
          }
          break;
        case 'product':
          const productData = {
            ...formData,
            price: Number(formData.price)
          };
          if (editItem) {
            await productsDB.updateData(editItem.id, productData);
            showSnackbar('Ürün başarıyla güncellendi');
          } else {
            await productsDB.addData(productData);
            showSnackbar('Yeni ürün başarıyla eklendi');
          }
          break;
        case 'table':
          if (editItem) {
            await tablesDB.updateData(editItem.id, formData);
            showSnackbar('Masa başarıyla güncellendi');
          } else {
            await tablesDB.addData(formData);
            showSnackbar('Yeni masa başarıyla eklendi');
          }
          break;
        case 'user':
          if (editItem) {
            await usersDB.updateData(editItem.id, formData);
            showSnackbar('Kullanıcı başarıyla güncellendi');
          } else {
            await usersDB.addData(formData);
            showSnackbar('Yeni kullanıcı başarıyla eklendi');
          }
          break;
        case 'option':
          const optionData = {
            name: formData.name,
            type: formData.type,
            options: formData.options || []
          };
          if (editItem) {
            await optionsDB.updateData(editItem.id, optionData);
            showSnackbar('Seçenek grubu başarıyla güncellendi');
          } else {
            await optionsDB.addData(optionData);
            showSnackbar('Yeni seçenek grubu başarıyla eklendi');
          }
          break;
        default:
          break;
      }
      handleCloseDialog();
    } catch (error) {
      showSnackbar(error.message, 'error');
    }
  };

  const handleDelete = async (type, id) => {
    try {
      switch (type) {
        case 'category':
          await categoriesDB.deleteData(id);
          showSnackbar('Kategori başarıyla silindi');
          break;
        case 'product':
          await productsDB.deleteData(id);
          showSnackbar('Ürün başarıyla silindi');
          break;
        case 'table':
          await tablesDB.deleteData(id);
          showSnackbar('Masa başarıyla silindi');
          break;
        case 'user':
          await usersDB.deleteData(id);
          showSnackbar('Kullanıcı başarıyla silindi');
          break;
        case 'option':
          await optionsDB.deleteData(id);
          showSnackbar('Seçenek grubu başarıyla silindi');
          break;
        default:
          break;
      }
    } catch (error) {
      showSnackbar(error.message, 'error');
    }
  };

  const handleUpdateRole = async (userId, newRole) => {
    // Kullanıcının admin olup olmadığını kontrol et
    if (user && usersDB.data && usersDB.data[user.uid]?.role !== 'admin') {
      showSnackbar('Sadece yöneticiler rol değişikliği yapabilir', 'error');
      return;
    }

    try {
      await usersDB.updateData(userId, { role: newRole });
      showSnackbar('Kullanıcı rolü güncellendi');
    } catch (error) {
      showSnackbar(error.message, 'error');
    }
  };

  const handleImportSampleData = async () => {
    const result = await importSampleData();
    if (result) {
      alert('Örnek veriler başarıyla import edildi!');
    } else {
      alert('Veri import edilirken bir hata oluştu!');
    }
  };

  const handleImportCategories = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const result = await importCategoriesFromExcel(file);
      showSnackbar(result.message, result.success ? 'success' : 'error');
    }
  };

  const handleImportProducts = async (event) => {
    const file = event.target.files[0];
    if (file && categoriesDB.data) {
      const result = await importProductsFromExcel(file, categoriesDB.data);
      showSnackbar(result.message, result.success ? 'success' : 'error');
    } else if (!categoriesDB.data) {
      showSnackbar('Önce kategorileri import etmelisiniz', 'error');
    }
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImageFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const uploadImage = async (file, path) => {
    if (!file) return null;
    
    const storage = getStorage();
    const fileRef = storageRef(storage, `${path}/${file.name}`);
    
    try {
      const snapshot = await uploadBytes(fileRef, file);
      const downloadURL = await getDownloadURL(snapshot.ref);
      return downloadURL;
    } catch (error) {
      console.error('Görsel yükleme hatası:', error);
      return null;
    }
  };

  const handleFormSubmit = async () => {
    const db = getDatabase();
    let imageUrl = formData.image;

    try {
      if (imageFile) {
        const path = dialogType === 'category' ? 'category-images' : 'product-images';
        imageUrl = await uploadImage(imageFile, path);
        if (!imageUrl) {
          showSnackbar('Görsel yüklenirken bir hata oluştu', 'error');
          return;
        }
      }

      // Eğer yeni bir kayıt ve resim yüklenmemişse
      if (!editItem && !imageUrl) {
        handleSave();
        return;
      }

      // Resim güncelleme işlemi
      if (imageUrl) {
        const timestamp = editItem ? editItem.id : Date.now();
        const path = `${dialogType}s/${timestamp}/image`;
        await set(ref(db, path), imageUrl);
      }

      handleSave();
    } catch (error) {
      console.error('Form gönderme hatası:', error);
      showSnackbar('İşlem sırasında bir hata oluştu', 'error');
    }
  };

  const renderDialog = () => {
    switch (dialogType) {
      case 'category':
        return (
          <>
            <DialogTitle>
              {editItem ? 'Kategori Düzenle' : 'Yeni Kategori'}
            </DialogTitle>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    autoFocus
                    margin="dense"
                    name="name"
                    label="Kategori Adı"
                    fullWidth
                    value={formData.name || ''}
                    onChange={handleFormChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Box sx={{ mb: 2, display: 'flex', alignItems: 'center', gap: 2 }}>
                    <Button
                      variant="outlined"
                      component="label"
                      startIcon={<UploadIcon />}
                    >
                      Görsel Seç
                      <input
                        type="file"
                        hidden
                        accept="image/*"
                        onChange={handleImageChange}
                      />
                    </Button>
                    {(imagePreview || formData.image) && (
                      <Avatar
                        src={imagePreview || formData.image}
                        variant="rounded"
                        sx={{ width: 100, height: 100 }}
                      />
                    )}
                  </Box>
                </Grid>
              </Grid>
            </DialogContent>
          </>
        );
      case 'product':
        return (
          <>
            <DialogTitle>
              {editItem ? 'Ürün Düzenle' : 'Yeni Ürün'}
            </DialogTitle>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    autoFocus
                    margin="dense"
                    name="name"
                    label="Ürün Adı"
                    fullWidth
                    value={formData.name || ''}
                    onChange={handleFormChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    margin="dense"
                    name="price"
                    label="Fiyat"
                    type="number"
                    fullWidth
                    value={formData.price || ''}
                    onChange={(e) => handleFormChange({
                      target: {
                        name: 'price',
                        value: Number(e.target.value)
                      }
                    })}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel>Kategori</InputLabel>
                    <Select
                      name="category"
                      value={formData.category || ''}
                      onChange={handleFormChange}
                    >
                      {categoriesDB.data && Object.entries(categoriesDB.data).map(([id, category]) => (
                        <MenuItem key={id} value={id}>
                          {category.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <Box sx={{ mb: 2, display: 'flex', alignItems: 'center', gap: 2 }}>
                    <Button
                      variant="outlined"
                      component="label"
                      startIcon={<UploadIcon />}
                    >
                      Görsel Seç
                      <input
                        type="file"
                        hidden
                        accept="image/*"
                        onChange={handleImageChange}
                      />
                    </Button>
                    {(imagePreview || formData.image) && (
                      <Avatar
                        src={imagePreview || formData.image}
                        variant="rounded"
                        sx={{ width: 100, height: 100 }}
                      />
                    )}
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="subtitle1" gutterBottom>
                    Ek Seçenekler
                  </Typography>
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                    {optionsDB.data && Object.entries(optionsDB.data).map(([id, option]) => (
                      <Chip
                        key={id}
                        label={option.name}
                        onClick={() => {
                          const selectedOptions = formData.selectedOptions || [];
                          const isSelected = selectedOptions.includes(id);
                          handleFormChange({
                            target: {
                              name: 'selectedOptions',
                              value: isSelected
                                ? selectedOptions.filter(optId => optId !== id)
                                : [...selectedOptions, id]
                            }
                          });
                        }}
                        color={formData.selectedOptions?.includes(id) ? 'primary' : 'default'}
                        variant={formData.selectedOptions?.includes(id) ? 'filled' : 'outlined'}
                        sx={{ m: 0.5 }}
                      />
                    ))}
                  </Box>
                </Grid>
              </Grid>
            </DialogContent>
          </>
        );
      case 'table':
        return (
          <>
            <DialogTitle>
              {editItem ? 'Masa Düzenle' : 'Yeni Masa'}
            </DialogTitle>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    autoFocus
                    margin="dense"
                    name="name"
                    label="Masa Adı"
                    fullWidth
                    value={formData.name || ''}
                    onChange={handleFormChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel>Masa Tipi</InputLabel>
                    <Select
                      name="type"
                      value={formData.type || 'small'}
                      onChange={handleFormChange}
                    >
                      <MenuItem value="small">2 Kişilik</MenuItem>
                      <MenuItem value="large">4 Kişilik</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    margin="dense"
                    name="posX"
                    label="X Pozisyonu"
                    type="number"
                    fullWidth
                    value={formData.posX || 0}
                    onChange={(e) => handleFormChange({
                      target: {
                        name: 'posX',
                        value: Number(e.target.value)
                      }
                    })}
                    helperText="Masanın yatay konumu"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    margin="dense"
                    name="posY"
                    label="Y Pozisyonu"
                    type="number"
                    fullWidth
                    value={formData.posY || 0}
                    onChange={(e) => handleFormChange({
                      target: {
                        name: 'posY',
                        value: Number(e.target.value)
                      }
                    })}
                    helperText="Masanın dikey konumu"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Box sx={{ mb: 2, display: 'flex', alignItems: 'center', gap: 2 }}>
                    <Button
                      variant="outlined"
                      component="label"
                      startIcon={<UploadIcon />}
                    >
                      Görsel Seç
                      <input
                        type="file"
                        hidden
                        accept="image/*"
                        onChange={handleImageChange}
                      />
                    </Button>
                    {(imagePreview || formData.image) && (
                      <Avatar
                        src={imagePreview || formData.image}
                        variant="rounded"
                        sx={{ width: 100, height: 100 }}
                      />
                    )}
                  </Box>
                </Grid>
              </Grid>
            </DialogContent>
          </>
        );
      case 'user':
        return (
          <>
            <DialogTitle>
              {editItem ? 'Kullanıcı Düzenle' : 'Yeni Kullanıcı'}
            </DialogTitle>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    autoFocus
                    margin="dense"
                    name="name"
                    label="Ad Soyad"
                    fullWidth
                    value={formData.name || ''}
                    onChange={handleFormChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    margin="dense"
                    name="email"
                    label="E-posta"
                    type="email"
                    fullWidth
                    value={formData.email || ''}
                    onChange={handleFormChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel>Rol</InputLabel>
                    <Select
                      name="role"
                      value={formData.role || ''}
                      onChange={handleFormChange}
                    >
                      <MenuItem value="admin">Admin</MenuItem>
                      <MenuItem value="kasiyer">Kasiyer</MenuItem>
                      <MenuItem value="barista">Barista</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </DialogContent>
          </>
        );
      case 'option':
        return (
          <>
            <DialogTitle>
              {editItem ? 'Seçenek Grubu Düzenle' : 'Yeni Seçenek Grubu'}
            </DialogTitle>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    autoFocus
                    margin="dense"
                    name="name"
                    label="Seçenek Grubu Adı"
                    fullWidth
                    value={formData.name || ''}
                    onChange={handleFormChange}
                    helperText="Örn: Şeker Seçimi, Shot Seçimi"
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel>Seçim Tipi</InputLabel>
                    <Select
                      name="type"
                      value={formData.type || 'radio'}
                      onChange={handleFormChange}
                    >
                      <MenuItem value="radio">Tek Seçim</MenuItem>
                      <MenuItem value="checkbox">Çoklu Seçim</MenuItem>
                    </Select>
                    <FormHelperText>
                      Tek Seçim: Sadece bir seçenek seçilebilir (örn: şeker miktarı)
                      Çoklu Seçim: Birden fazla seçenek seçilebilir (örn: ekstralar)
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="subtitle2" gutterBottom>
                    Seçenekler
                  </Typography>
                  {formData.options?.map((option, index) => (
                    <Box key={index} sx={{ display: 'flex', gap: 1, mb: 1 }}>
                      <TextField
                        size="small"
                        label="Seçenek Adı"
                        value={option.name}
                        onChange={(e) => {
                          const newOptions = [...(formData.options || [])];
                          newOptions[index] = { ...option, name: e.target.value };
                          handleFormChange({
                            target: {
                              name: 'options',
                              value: newOptions
                            }
                          });
                        }}
                        sx={{ flex: 2 }}
                      />
                      <TextField
                        size="small"
                        label="Ek Ücret"
                        type="number"
                        value={option.price || 0}
                        onChange={(e) => {
                          const newOptions = [...(formData.options || [])];
                          newOptions[index] = { ...option, price: Number(e.target.value) };
                          handleFormChange({
                            target: {
                              name: 'options',
                              value: newOptions
                            }
                          });
                        }}
                        sx={{ flex: 1 }}
                      />
                      <IconButton 
                        size="small" 
                        onClick={() => {
                          const newOptions = formData.options.filter((_, i) => i !== index);
                          handleFormChange({
                            target: {
                              name: 'options',
                              value: newOptions
                            }
                          });
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  ))}
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={() => {
                      const newOptions = [...(formData.options || []), { name: '', price: 0 }];
                      handleFormChange({
                        target: {
                          name: 'options',
                          value: newOptions
                        }
                      });
                    }}
                  >
                    Seçenek Ekle
                  </Button>
                </Grid>
              </Grid>
            </DialogContent>
          </>
        );
      default:
        return null;
    }
  };

  // DataGrid için kolon tanımlamaları
  const categoryColumns = [
    { field: 'image', headerName: 'Görsel', width: 100, 
      renderCell: (params) => (
        <Avatar src={params.value} variant="rounded">
          {params.row.name?.charAt(0)}
        </Avatar>
      )
    },
    { field: 'name', headerName: 'Kategori Adı', flex: 1 },
    { field: 'actions', headerName: 'İşlemler', width: 120,
      renderCell: (params) => (
        <Box>
          <IconButton
            onClick={() => handleOpenDialog('category', { id: params.row.id, ...params.row })}
            size="small"
            sx={{ mr: 1 }}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            onClick={() => handleDelete('category', params.row.id)}
            size="small"
          >
            <DeleteIcon />
          </IconButton>
        </Box>
      )
    },
  ];

  const productColumns = [
    { field: 'image', headerName: 'Görsel', width: 100,
      renderCell: (params) => (
        <Avatar src={params.value} variant="rounded">
          {params.row.name?.charAt(0)}
        </Avatar>
      )
    },
    { field: 'name', headerName: 'Ürün Adı', flex: 1 },
    { field: 'price', headerName: 'Fiyat', width: 120,
      renderCell: (params) => {
        const price = Number(params.row.price);
        return `${price.toFixed(2)} ₺`;
      }
    },
    { field: 'category', headerName: 'Kategori', width: 150,
      renderCell: (params) => {
        const categoryId = params.row.category;
        const category = categoriesDB.data?.[categoryId];
        return category?.name || 'Kategori Yok';
      }
    },
    { field: 'selectedOptions', headerName: 'Ek Seçenekler', flex: 1,
      renderCell: (params) => {
        const selectedOptionIds = params.row.selectedOptions || [];
        return (
          <Box sx={{ 
            display: 'flex', 
            gap: 1,
            width: '100%',
            height: '100%',
            alignItems: 'center' 
          }}>
            {selectedOptionIds.map((optionId) => {
              const option = optionsDB.data?.[optionId];
              return option ? (
                <Chip
                  key={optionId}
                  label={option.name}
                  size="small"
                  variant="outlined"
                />
              ) : null;
            })}
          </Box>
        );
      }
    },
    { field: 'actions', headerName: 'İşlemler', width: 120,
      renderCell: (params) => (
        <Box>
          <IconButton
            onClick={() => handleOpenDialog('product', { id: params.row.id, ...params.row })}
            size="small"
            sx={{ mr: 1 }}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            onClick={() => handleDelete('product', params.row.id)}
            size="small"
          >
            <DeleteIcon />
          </IconButton>
        </Box>
      )
    },
  ];

  // Sürükle-bırak işlemleri için yeni fonksiyonlar
  const handleDragStop = (tableId, e, data) => {
    // En yakın grid noktalarına yuvarla
    const snappedX = snapToGrid(data.x);
    const snappedY = snapToGrid(data.y);
    
    tablesDB.updateData(tableId, {
      posX: snappedX,
      posY: snappedY
    });
  };

  // Masalar sekmesi için yeni render fonksiyonu
  const renderTableLayout = () => {
    const gridTables = getGridRows(tablesDB.data);
    
    // Grid çizgilerini oluştur
    const gridLines = [];
    const containerWidth = 800; // Container genişliği
    const containerHeight = 600; // Container yüksekliği
    
    // Dikey çizgiler
    for (let x = GRID_SIZE; x < containerWidth; x += GRID_SIZE) {
      gridLines.push(
        <Box
          key={`vertical-${x}`}
          sx={{
            position: 'absolute',
            left: x,
            top: 0,
            width: '1px',
            height: '100%',
            backgroundColor: 'rgba(0,0,0,0.1)'
          }}
        />
      );
    }
    
    // Yatay çizgiler
    for (let y = GRID_SIZE; y < containerHeight; y += GRID_SIZE) {
      gridLines.push(
        <Box
          key={`horizontal-${y}`}
          sx={{
            position: 'absolute',
            left: 0,
            top: y,
            width: '100%',
            height: '1px',
            backgroundColor: 'rgba(0,0,0,0.1)'
          }}
        />
      );
    }
    
    return (
      <Box sx={{ position: 'relative', width: containerWidth, height: containerHeight, border: '1px solid #ccc', backgroundColor: '#f5f5f5' }}>
        {/* Grid çizgileri */}
        {gridLines}
        
        {/* Masalar */}
        {gridTables.map((table) => (
          <Draggable
            key={table.id}
            defaultPosition={{ x: table.posX || 0, y: table.posY || 0 }}
            onStop={(e, data) => handleDragStop(table.id, e, data)}
            bounds="parent"
            grid={[GRID_SIZE, GRID_SIZE]}
          >
            <Paper
              elevation={3}
              sx={{
                position: 'absolute',
                width: table.type === 'large' ? TABLE_BASE_WIDTH * 2 : TABLE_BASE_WIDTH,
                height: TABLE_HEIGHT,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'move',
                backgroundColor: selectedTable === table.id ? '#e3f2fd' : 'white',
                '&:hover': {
                  backgroundColor: '#f5f5f5'
                }
              }}
              onClick={() => setSelectedTable(table.id)}
            >
              <Typography sx={{ 
                fontWeight: 'bold',
                color: 'black',
                fontSize: '1.1rem'
              }}>
                {table.name}
              </Typography>
            </Paper>
          </Draggable>
        ))}
      </Box>
    );
  };

  // DataGrid kolon tanımlamalarını güncelle
  const tableColumns = [
    { field: 'name', headerName: 'Masa Adı', flex: 1 },
    { field: 'type', headerName: 'Masa Tipi', width: 120,
      renderCell: (params) => {
        const typeMap = {
          small: '2 Kişilik',
          large: '4 Kişilik'
        };
        return typeMap[params.row?.type] || '2 Kişilik';
      }
    },
    { field: 'posX', headerName: 'X Pozisyonu', width: 120,
      renderCell: (params) => params.row?.posX || 0
    },
    { field: 'posY', headerName: 'Y Pozisyonu', width: 120,
      renderCell: (params) => params.row?.posY || 0
    },
    { field: 'actions', headerName: 'İşlemler', width: 120,
      renderCell: (params) => (
        <Box>
          <IconButton
            onClick={() => handleOpenDialog('table', { id: params.id, ...params.row })}
            size="small"
            sx={{ mr: 1 }}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            onClick={() => handleDelete('table', params.id)}
            size="small"
          >
            <DeleteIcon />
          </IconButton>
        </Box>
      )
    },
  ];

  // Veriyi DataGrid formatına dönüştürme fonksiyonları
  const getGridRows = (data) => {
    return data ? Object.entries(data).map(([id, item]) => ({
      id,
      ...item
    })) : [];
  };

  // Ek seçenek grubu için kolon tanımlamaları
  const optionColumns = [
    { field: 'name', headerName: 'Seçenek Grubu', flex: 1 },
    { field: 'type', headerName: 'Seçim Tipi', width: 120,
      valueFormatter: (params) => {
        if (!params?.value) return '';
        return params.value === 'radio' ? 'Tek Seçim' : 'Çoklu Seçim';
      }
    },
    { field: 'options', headerName: 'Seçenekler', flex: 2,
      renderCell: (params) => (
        <Box sx={{ display: 'flex', gap: 1 }}>
          {params.value?.map((option, index) => (
            <Chip
              key={index}
              label={`${option.name} ${option.price > 0 ? `(+${option.price}₺)` : ''}`}
              size="small"
            />
          ))}
        </Box>
      )
    },
    { field: 'actions', headerName: 'İşlemler', width: 120,
      renderCell: (params) => (
        <Box>
          <IconButton
            onClick={() => handleOpenDialog('option', { id: params.id, ...params.row })}
            size="small"
            sx={{ mr: 1 }}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            onClick={() => handleDelete('option', params.id)}
            size="small"
          >
            <DeleteIcon />
          </IconButton>
        </Box>
      )
    },
  ];

  if (categoriesDB.loading || productsDB.loading || tablesDB.loading || usersDB.loading || optionsDB.loading) {
    return <Typography>Yükleniyor...</Typography>;
  }

  if (categoriesDB.error || productsDB.error || tablesDB.error || usersDB.error || optionsDB.error) {
    return <Typography color="error">Bir hata oluştu!</Typography>;
  }

  return (
    <Box sx={{ 
      p: 3, 
      height: 'calc(100vh - 100px)', 
      overflowY: 'auto',
      '&::-webkit-scrollbar': {
        width: '8px',
      },
      '&::-webkit-scrollbar-track': {
        background: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.05)' : '#f1f1f1',
      },
      '&::-webkit-scrollbar-thumb': {
        background: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.2)' : '#888',
        borderRadius: '4px',
      },
      '&::-webkit-scrollbar-thumb:hover': {
        background: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.3)' : '#555',
      },
    }}>
      <Typography variant="h4" gutterBottom>
        Ayarlar
      </Typography>

      <Paper sx={{ width: '100%', mb: 2 }}>
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab label="Kategoriler" />
          <Tab label="Ürünler" />
          <Tab label="Masalar" />
          <Tab label="Kullanıcılar" />
          <Tab label="Ek Seçenekler" />
        </Tabs>
      </Paper>

      <Box sx={{ mt: 2 }}>
        {/* Kategoriler */}
        {activeTab === 0 && (
          <Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
              <Typography variant="h6">Kategoriler</Typography>
              <Button
                variant="contained"
                startIcon={<AddIcon />}
                onClick={() => handleOpenDialog('category')}
              >
                Yeni Kategori
              </Button>
            </Box>
            <DataGrid
              rows={getGridRows(categoriesDB.data)}
              columns={categoryColumns}
              pageSize={10}
              rowsPerPageOptions={[10]}
              autoHeight
              disableSelectionOnClick
              sx={{ 
                backgroundColor: theme.palette.mode === 'dark' ? 'background.paper' : 'white',
                '& .MuiDataGrid-cell': {
                  color: theme.palette.text.primary
                },
                '& .MuiDataGrid-columnHeaders': {
                  backgroundColor: theme.palette.mode === 'dark' ? 'background.paper' : 'white',
                  color: theme.palette.text.primary
                }
              }}
            />
          </Box>
        )}

        {/* Ürünler */}
        {activeTab === 1 && (
          <Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
              <Typography variant="h6">Ürünler</Typography>
              <Button
                variant="contained"
                startIcon={<AddIcon />}
                onClick={() => handleOpenDialog('product')}
              >
                Yeni Ürün
              </Button>
            </Box>
            <DataGrid
              rows={getGridRows(productsDB.data)}
              columns={productColumns}
              pageSize={10}
              rowsPerPageOptions={[10]}
              autoHeight
              disableSelectionOnClick
              sx={{ 
                backgroundColor: theme.palette.mode === 'dark' ? 'background.paper' : 'white',
                '& .MuiDataGrid-cell': {
                  color: theme.palette.text.primary
                },
                '& .MuiDataGrid-columnHeaders': {
                  backgroundColor: theme.palette.mode === 'dark' ? 'background.paper' : 'white',
                  color: theme.palette.text.primary
                }
              }}
            />
          </Box>
        )}

        {/* Masalar */}
        {activeTab === 2 && (
          <Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
              <Typography variant="h6">Masalar</Typography>
              <Button
                variant="contained"
                startIcon={<AddIcon />}
                onClick={() => handleOpenDialog('table')}
              >
                Yeni Masa
              </Button>
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Typography variant="subtitle1" sx={{ mb: 2 }}>Masa Listesi</Typography>
                <DataGrid
                  rows={getGridRows(tablesDB.data)}
                  columns={tableColumns}
                  pageSize={10}
                  rowsPerPageOptions={[10]}
                  autoHeight
                  disableSelectionOnClick
                  sx={{ 
                    backgroundColor: theme.palette.mode === 'dark' ? 'background.paper' : 'white',
                    '& .MuiDataGrid-cell': {
                      color: theme.palette.text.primary
                    },
                    '& .MuiDataGrid-columnHeaders': {
                      backgroundColor: theme.palette.mode === 'dark' ? 'background.paper' : 'white',
                      color: theme.palette.text.primary
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="subtitle1" sx={{ mb: 2 }}>Masa Düzeni</Typography>
                {renderTableLayout()}
              </Grid>
            </Grid>
          </Box>
        )}

        {/* Kullanıcılar */}
        {activeTab === 3 && (
          <Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
              <Typography variant="h6">Kullanıcılar</Typography>
            </Box>
            <Grid container spacing={3}>
              {usersDB.data && Object.entries(usersDB.data).map(([id, userData]) => (
                <Grid item xs={12} sm={6} md={4} key={id}>
                  <Paper
                    elevation={2}
                    sx={{
                      p: 2,
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      position: 'relative'
                    }}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                      <Box
                        component="img"
                        src={userData.photoURL}
                        alt={userData.displayName}
                        sx={{
                          width: 50,
                          height: 50,
                          borderRadius: '50%',
                          mr: 2,
                          objectFit: 'cover'
                        }}
                      />
                      <Box>
                        <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                          {userData.displayName || 'İsimsiz Kullanıcı'}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          {userData.email}
                        </Typography>
                      </Box>
                    </Box>
                    
                    <Box sx={{ mt: 'auto' }}>
                      <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
                        Son Giriş: {new Date(userData.lastLogin).toLocaleString('tr-TR')}
                      </Typography>
                      
                      <FormControl 
                        fullWidth 
                        size="small"
                        disabled={!user || usersDB.data[user.uid]?.role !== 'admin'}
                      >
                        <InputLabel>Kullanıcı Rolü</InputLabel>
                        <Select
                          value={userData.role || ''}
                          onChange={(e) => handleUpdateRole(id, e.target.value)}
                          label="Kullanıcı Rolü"
                        >
                          <MenuItem value="">
                            <em>Rol atanmamış</em>
                          </MenuItem>
                          <MenuItem value="admin">Admin</MenuItem>
                          <MenuItem value="kasiyer">Kasiyer</MenuItem>
                          <MenuItem value="barista">Barista</MenuItem>
                        </Select>
                        {(!user || usersDB.data[user.uid]?.role !== 'admin') && (
                          <FormHelperText error>
                            * Sadece yöneticiler rol değişikliği yapabilir
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Box>
                  </Paper>
                </Grid>
              ))}
            </Grid>
          </Box>
        )}

        {/* Ek Seçenekler */}
        {activeTab === 4 && (
          <Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
              <Typography variant="h6">Ek Seçenekler</Typography>
              <Button
                variant="contained"
                startIcon={<AddIcon />}
                onClick={() => handleOpenDialog('option')}
              >
                Yeni Seçenek Grubu
              </Button>
            </Box>
            <DataGrid
              rows={getGridRows(optionsDB.data)}
              columns={optionColumns}
              pageSize={10}
              rowsPerPageOptions={[10]}
              autoHeight
              disableSelectionOnClick
              sx={{ 
                backgroundColor: theme.palette.mode === 'dark' ? 'background.paper' : 'white',
                '& .MuiDataGrid-cell': {
                  color: theme.palette.text.primary
                },
                '& .MuiDataGrid-columnHeaders': {
                  backgroundColor: theme.palette.mode === 'dark' ? 'background.paper' : 'white',
                  color: theme.palette.text.primary
                }
              }}
            />
          </Box>
        )}
      </Box>

      {/* Genel Dialog */}
      <Dialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        maxWidth="sm"
        fullWidth
      >
        {renderDialog()}
        <DialogActions>
          <Button onClick={handleCloseDialog}>İptal</Button>
          <Button onClick={handleFormSubmit} variant="contained">
            Kaydet
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Ayarlar; 