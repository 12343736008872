import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Grid,
  Paper,
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Card,
  CardContent,
  Chip,
  Divider,
  IconButton,
  useTheme,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@mui/material';
import {
  Add as AddIcon,
  Remove as RemoveIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  CardGiftcard as CardGiftcardIcon,
  CompareArrows as CompareArrowsIcon,
} from '@mui/icons-material';
import { useDatabase } from '../firebase/hooks/useDatabase';
import { useAuth } from '../firebase/hooks/useAuth';

const TableOrder = () => {
  const { tableId } = useParams();
  const { user } = useAuth();
  const theme = useTheme();
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [activeOrders, setActiveOrders] = useState([]);
  const [orderDialog, setOrderDialog] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [orderNote, setOrderNote] = useState('');
  const [selectedOptions, setSelectedOptions] = useState({});
  const [editDialog, setEditDialog] = useState(null);
  const [complimentaryDialog, setComplimentaryDialog] = useState({
    open: false,
    item: null,
    orderIndex: -1,
    itemIndex: -1
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [transferDialogOpen, setTransferDialogOpen] = useState(false);
  const navigate = useNavigate();
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  const tablesDB = useDatabase('tables');
  const categoriesDB = useDatabase('categories');
  const productsDB = useDatabase('products');
  const ordersDB = useDatabase('orders');
  const optionsDB = useDatabase('options');
  const paymentsDB = useDatabase('payments');

  useEffect(() => {
    const fetchOrders = async () => {
      if (ordersDB.data && user) {
        const tableOrders = Object.entries(ordersDB.data)
          .filter(([_, order]) => order.tableId === tableId)
          .map(([id, order]) => ({ id, ...order }));
        setActiveOrders(tableOrders);
      }
    };

    fetchOrders();
  }, [ordersDB.data, tableId, user]);

  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleOptionSelect = (optionId, selectedOpt) => {
    const option = optionsDB.data?.[optionId];
    if (!option) return;

    setSelectedOptions(prev => ({
      ...prev,
      [optionId]: option.type === 'radio' 
        ? selectedOpt
        : prev[optionId] 
          ? prev[optionId].includes(selectedOpt)
            ? prev[optionId].filter(opt => opt !== selectedOpt)
            : [...prev[optionId], selectedOpt]
          : [selectedOpt]
    }));
  };

  const calculateOptionPrice = (options) => {
    let totalPrice = 0;
    Object.entries(options).forEach(([optionId, selected]) => {
      const option = optionsDB.data?.[optionId];
      if (!option) return;

      if (Array.isArray(selected)) {
        selected.forEach(sel => {
          const opt = option.options.find(o => o.name === sel);
          if (opt) totalPrice += opt.price;
        });
      } else {
        const opt = option.options.find(o => o.name === selected);
        if (opt) totalPrice += opt.price;
      }
    });
    return totalPrice;
  };

  const handleProductClick = (product) => {
    setSelectedProduct(product);
    setQuantity(1);
    setOrderNote('');
    
    // Tüm seçenekler için varsayılan değerleri ayarla
    if (product.selectedOptions?.length > 0) {
      const defaultOptions = {};
      product.selectedOptions.forEach(optId => {
        const option = optionsDB.data?.[optId];
        if (option) {
          if (option.type === 'radio') {
            defaultOptions[optId] = option.options[0]?.name;
          } else {
            defaultOptions[optId] = [option.options[0]?.name];
          }
        }
      });
      setSelectedOptions(defaultOptions);
    } else {
      setSelectedOptions({});
    }
    
    setOrderDialog(true);
  };

  const handleSubmitOrder = async () => {
    if (isSubmitting) return;
    
    setIsSubmitting(true);
    
    try {
      const optionPrice = calculateOptionPrice(selectedOptions);
      const totalPrice = (selectedProduct.price + optionPrice) * quantity;

      const orderItem = {
        ...selectedProduct,
        quantity,
        selectedOptionDetails: selectedOptions,
        note: orderNote,
        price: selectedProduct.price + optionPrice,
        total: totalPrice
      };

      if (editDialog) {
        // Düzenleme işlemi
        const newOrders = [...activeOrders];
        const order = newOrders[editDialog.orderIndex];
        order.items[editDialog.itemIndex] = orderItem;
        
        // Sipariş toplamını güncelle
        order.total = order.items.reduce((total, item) => total + item.total, 0);
        
        await ordersDB.updateData(order.id, order);
      } else {
        // Yeni sipariş
        const newOrder = {
          tableId,
          items: [orderItem],
          total: totalPrice,
          status: 'active',
          timestamp: new Date().toISOString(),
          waiter: {
            id: user.uid,
            name: user.displayName || user.email
          }
        };

        // Önce masa durumunu güncelle
        await tablesDB.updateData(tableId, { status: 'occupied' });
        
        // Sonra siparişi ekle
        await ordersDB.addData(newOrder);
      }

      setOrderDialog(false);
      setSelectedProduct(null);
      setQuantity(1);
      setOrderNote('');
      setSelectedOptions({});
      setIsSubmitting(false);
      
    } catch (error) {
      console.error('Sipariş işlemi sırasında hata:', error);
      setIsSubmitting(false);
    }
  };

  const handleEditItem = (orderIndex, itemIndex, item) => {
    setSelectedProduct(item);
    setQuantity(item.quantity);
    setOrderNote(item.note || '');
    setSelectedOptions(item.selectedOptionDetails || {});
    setEditDialog({
      open: true,
      orderIndex,
      itemIndex,
      item: { ...item }
    });
    setOrderDialog(true);
  };

  const handleDeleteItem = async (orderIndex, itemIndex) => {
    if (window.confirm('Bu siparişi silmek istediğinize emin misiniz?')) {
      const newOrders = [...activeOrders];
      const order = newOrders[orderIndex];
      order.items.splice(itemIndex, 1);
      
      if (order.items.length === 0) {
        // Sipariş boşsa, siparişi tamamen sil
        await ordersDB.deleteData(order.id);
        newOrders.splice(orderIndex, 1);

        // Eğer masada başka sipariş kalmadıysa masa durumunu güncelle
        if (newOrders.length === 0) {
          await tablesDB.updateData(tableId, { status: 'empty' });
        }
      } else {
        // Siparişte hala ürün varsa, siparişi güncelle
        order.total = order.items.reduce((total, item) => total + item.total, 0);
        await ordersDB.updateData(order.id, order);
      }
      
      setActiveOrders(newOrders);
    }
  };

  const handleComplimentaryClick = (orderIndex, itemIndex, item) => {
    setComplimentaryDialog({
      open: true,
      item,
      orderIndex,
      itemIndex,
      currentComplimentary: item.complimentaryQuantity || 0
    });
  };

  const handleComplimentaryApply = (quantity) => {
    const { orderIndex, itemIndex } = complimentaryDialog;
    const newOrders = [...activeOrders];
    const item = newOrders[orderIndex].items[itemIndex];
    
    item.complimentaryQuantity = quantity;
    
    // Toplam tutarı güncelle
    const order = newOrders[orderIndex];
    order.total = order.items.reduce((total, item) => 
      total + ((item.total / item.quantity) * (item.quantity - (item.complimentaryQuantity || 0))), 0
    );
    
    setActiveOrders(newOrders);
    setComplimentaryDialog({ open: false, item: null, orderIndex: -1, itemIndex: -1 });
    
    // Veritabanını güncelle
    ordersDB.updateData(order.id, order);
  };

  const handleTableTransfer = async (targetTableId) => {
    try {
      // Kaynak masanın verilerini al
      const sourceTableData = await tablesDB.getData(tableId);
      
      // Siparişleri al
      const sourceOrders = ordersDB.data ? Object.entries(ordersDB.data)
        .filter(([_, order]) => order.tableId === tableId)
        .map(([id, order]) => ({ id, ...order })) : [];

      // Ödemeleri al ve güncelle
      const paymentUpdates = [];
      if (paymentsDB.data) {
        Object.entries(paymentsDB.data).forEach(([date, datePayments]) => {
          if (typeof datePayments === 'object') {
            Object.entries(datePayments).forEach(([time, timePayments]) => {
              if (typeof timePayments === 'object') {
                Object.entries(timePayments).forEach(([id, payment]) => {
                  if (payment.tableId === tableId) {
                    // Yeni bir kayıt oluştur
                    const updatedPayment = {
                      ...payment,
                      tableId: targetTableId,
                      transferredFrom: tableId,
                      transferTime: new Date().toISOString()
                    };
                    
                    // Eski kaydı güncelle
                    paymentUpdates.push(
                      paymentsDB.updateData(
                        `${date}/${time}/${id}`,
                        updatedPayment
                      )
                    );
                  }
                });
              }
            });
          }
        });
      }

      // Hedef masanın verilerini al
      const targetTableData = await tablesDB.getData(targetTableId);

      // Kaynak masayı sıfırla
      const now = new Date().toISOString();
      await tablesDB.updateData(tableId, {
        status: 'empty',
        paidAmount: 0,
        discountAmount: 0,
        lastPaymentTime: null,
        lastPaymentAmount: 0,
        lastClosingTime: now
      });

      // Hedef masayı güncelle
      await tablesDB.updateData(targetTableId, {
        status: 'occupied',
        paidAmount: sourceTableData?.paidAmount || 0,
        discountAmount: sourceTableData?.discountAmount || 0,
        lastPaymentTime: sourceTableData?.lastPaymentTime || null,
        lastPaymentAmount: sourceTableData?.lastPaymentAmount || 0,
        lastClosingTime: sourceTableData?.lastClosingTime || null
      });

      // Siparişleri yeni masaya taşı
      const orderPromises = sourceOrders.map(order => {
        const updatedOrder = {
          ...order,
          tableId: targetTableId,
          items: order.items.map(item => ({
            ...item,
            paidAmount: item.paidAmount || 0,
            discountAmount: item.discountAmount || 0
          }))
        };
        return ordersDB.updateData(order.id, updatedOrder);
      });

      // Tüm güncellemeleri bekle
      await Promise.all([...orderPromises, ...paymentUpdates]);

      // Dialog'u kapat
      setTransferDialogOpen(false);

      // Kullanıcıyı yeni masaya yönlendir
      navigate(`/table/${targetTableId}`);
    } catch (error) {
      console.error('Masa taşıma sırasında hata:', error);
    }
  };

  if (tablesDB.loading || categoriesDB.loading || productsDB.loading || ordersDB.loading) {
    return <Typography>Yükleniyor...</Typography>;
  }

  const categories = categoriesDB.data ? Object.entries(categoriesDB.data).map(([id, category]) => ({
    ...category,
    id
  })) : [];
  const products = productsDB.data ? Object.values(productsDB.data) : [];
  const table = tablesDB.data?.[tableId];

  console.log('Kategoriler:', categories);
  console.log('Seçili Kategori:', selectedCategory, categories[selectedCategory]);
  console.log('Ürünler:', products);
  console.log('Filtrelenmiş Ürünler:', products.filter(product => {
    const currentCategory = categories[selectedCategory];
    if (!currentCategory) return false;
    console.log('Karşılaştırma:', {
      productCategory: product.category,
      selectedCategory: currentCategory.id,
      isMatch: product.category === currentCategory.id
    });
    return product.category === currentCategory.id;
  }));

  return (
    <Box sx={{ 
      p: { xs: 1, md: 3 },
      height: '100vh',
      overflow: 'auto'
    }}>
      <Typography variant="h5" gutterBottom>
        {table?.name} - Sipariş
      </Typography>

      <Grid container spacing={2}>
        {/* Sol Panel - Aktif Siparişler */}
        <Grid item xs={12} md={3}>
          <Paper sx={{ 
            p: { xs: 1, md: 2 }, 
            height: { xs: 'auto', md: 'calc(100vh - 180px)' },
            maxHeight: { xs: `${windowHeight * 0.25}px`, md: 'calc(100vh - 180px)' },
            overflow: 'auto',
            '&::-webkit-scrollbar': {
              width: '8px',
            },
            '&::-webkit-scrollbar-track': {
              backgroundColor: theme => theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.05)',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: theme => theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.15)' : 'rgba(0, 0, 0, 0.15)',
              borderRadius: '4px',
              '&:hover': {
                backgroundColor: theme => theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.25)' : 'rgba(0, 0, 0, 0.25)',
              }
            }
          }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
              <Typography variant="h6">
                Aktif Siparişler
              </Typography>
              {activeOrders.length > 0 && (
                <Button
                  variant="outlined"
                  startIcon={<CompareArrowsIcon />}
                  onClick={() => setTransferDialogOpen(true)}
                >
                  Masa Taşı
                </Button>
              )}
            </Box>
            {activeOrders.map((order, orderIndex) => (
              <Box key={order.id} sx={{ mb: 2 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
                  <Typography variant="subtitle2" color="text.secondary">
                    {new Date(order.timestamp).toLocaleTimeString()}
                  </Typography>
                  <Typography variant="caption" color="text.secondary">
                    {order.waiter?.name}
                  </Typography>
                </Box>
                <List dense>
                  {order.items.map((item, itemIndex) => (
                    <ListItem
                      key={item.id}
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'flex-start',
                        py: 1.5,
                        gap: 1,
                        cursor: 'pointer'
                      }}
                    >
                      <Box sx={{ flex: 1 }}>
                        <Typography variant="body1">
                          {item.name} x{item.quantity}
                        </Typography>
                        {(item.selectedOptionDetails && Object.keys(item.selectedOptionDetails).length > 0) && (
                          <Typography variant="caption" color="text.secondary" display="block">
                            {Object.entries(item.selectedOptionDetails).map(([optionId, selected]) => (
                              Array.isArray(selected) ? selected.join(', ') : selected
                            )).filter(Boolean).join(', ')}
                          </Typography>
                        )}
                        {item.note && (
                          <Typography variant="caption" color="text.secondary" display="block">
                            Not: {item.note}
                          </Typography>
                        )}
                      </Box>

                      <Box sx={{ 
                        display: 'flex', 
                        alignItems: 'center',
                        gap: 2,
                        minWidth: 'fit-content'
                      }}>
                        <Typography variant="body2" color="primary">
                          {item.total.toFixed(2)} ₺
                        </Typography>
                        
                        {item.status === 'complete' && (
                          <Box sx={{ 
                            bgcolor: 'success.main',
                            color: 'white',
                            px: 1,
                            py: 0.5,
                            borderRadius: 1,
                            fontSize: '0.75rem'
                          }}>
                            Hazır
                          </Box>
                        )}

                        {(!item.status || item.status === 'active') && (
                          <Box sx={{ display: 'flex', gap: 1 }}>
                            <IconButton
                              size="small"
                              onClick={(e) => {
                                e.stopPropagation();
                                handleEditItem(orderIndex, itemIndex, item);
                              }}
                              sx={{ cursor: 'pointer' }}
                            >
                              <EditIcon fontSize="small" />
                            </IconButton>
                            <IconButton
                              size="small"
                              onClick={(e) => {
                                e.stopPropagation();
                                handleDeleteItem(orderIndex, itemIndex);
                              }}
                              sx={{ cursor: 'pointer' }}
                            >
                              <DeleteIcon fontSize="small" />
                            </IconButton>
                          </Box>
                        )}
                      </Box>
                    </ListItem>
                  ))}
                </List>
                <Typography variant="subtitle1" align="right" sx={{ mt: 1 }}>
                  Toplam: {order.total.toFixed(2)} ₺
                </Typography>
                <Divider sx={{ my: 1 }} />
              </Box>
            ))}
          </Paper>
        </Grid>

        {/* Sağ Panel - Sipariş Girişi */}
        <Grid item xs={12} md={9}>
          <Grid container spacing={2}>
            {/* Kategoriler - Mobil için Dropdown, Desktop için Liste */}
            <Grid item xs={12} md={3}>
              {/* Mobil Görünüm - Dropdown */}
              <Box sx={{ display: { xs: 'block', md: 'none' }, mb: 2 }}>
                <FormControl fullWidth size="small">
                  <InputLabel>Kategori Seçin</InputLabel>
                  <Select
                    value={selectedCategory}
                    onChange={(e) => setSelectedCategory(e.target.value)}
                    label="Kategori Seçin"
                  >
                    {categories.map((category, index) => (
                      <MenuItem key={category.id} value={index}>
                        {category.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>

              {/* Desktop Görünüm - Liste */}
              <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                <Paper sx={{ 
                  p: { xs: 1, md: 1 }, 
                  height: { xs: 'auto', md: 'calc(100vh - 180px)' },
                  maxHeight: { xs: '200px', md: 'calc(100vh - 180px)' },
                  overflow: 'auto',
                  '&::-webkit-scrollbar': {
                    width: '8px',
                  },
                  '&::-webkit-scrollbar-track': {
                    backgroundColor: theme => theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.05)',
                  },
                  '&::-webkit-scrollbar-thumb': {
                    backgroundColor: theme => theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.15)' : 'rgba(0, 0, 0, 0.15)',
                    borderRadius: '4px',
                    '&:hover': {
                      backgroundColor: theme => theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.25)' : 'rgba(0, 0, 0, 0.25)',
                    }
                  }
                }}>
                  <List sx={{
                    '& .MuiListItem-root': {
                      py: { xs: 0.5, md: 1 }
                    }
                  }}>
                    {categories.map((category, index) => (
                      <ListItem
                        key={category.id}
                        onClick={() => setSelectedCategory(index)}
                        sx={{
                          borderRadius: 1,
                          mb: 0.5,
                          cursor: 'pointer',
                          backgroundColor: selectedCategory === index ? 'primary.main' : 'transparent',
                          color: selectedCategory === index ? 'white' : 'inherit',
                          '&:hover': {
                            backgroundColor: selectedCategory === index ? 'primary.dark' : 'action.hover',
                          }
                        }}
                      >
                        <ListItemText 
                          primary={category.name}
                          primaryTypographyProps={{
                            sx: { fontWeight: selectedCategory === index ? 'bold' : 'normal' }
                          }}
                        />
                      </ListItem>
                    ))}
                  </List>
                </Paper>
              </Box>
            </Grid>

            {/* Ürünler */}
            <Grid item xs={12} md={9}>
              <Paper sx={{ 
                p: { xs: 1, md: 2 }, 
                height: { xs: `${windowHeight * 0.45}px`, md: 'calc(100vh - 180px)' },
                overflow: 'auto',
                '&::-webkit-scrollbar': {
                  width: '8px',
                },
                '&::-webkit-scrollbar-track': {
                  backgroundColor: theme => theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.05)',
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: theme => theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.15)' : 'rgba(0, 0, 0, 0.15)',
                  borderRadius: '4px',
                  '&:hover': {
                    backgroundColor: theme => theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.25)' : 'rgba(0, 0, 0, 0.25)',
                  }
                }
              }}>
                <Grid container spacing={1}>
                  {products
                    .filter(product => {
                      const currentCategory = categories[selectedCategory];
                      if (!currentCategory) return false;
                      return product.category === currentCategory.id;
                    })
                    .map(product => (
                      <Grid item xs={6} sm={4} md={4} key={product.id}>
                        <Card
                          onClick={() => handleProductClick(product)}
                          sx={{
                            cursor: 'pointer',
                            display: 'flex',
                            flexDirection: 'column',
                            height: '100%',
                            '&:hover': {
                              transform: 'translateY(-2px)',
                              boxShadow: 3
                            }
                          }}
                        >
                          <CardContent sx={{ p: { xs: 1, md: 2 }, '&:last-child': { pb: { xs: 1, md: 2 } } }}>
                            <Typography variant="subtitle1" sx={{ fontSize: { xs: '0.875rem', md: '1.1rem' }, mb: 0.5 }}>
                              {product.name}
                            </Typography>
                            <Typography variant="body2" color="primary" sx={{ fontSize: { xs: '0.8rem', md: '1rem' } }}>
                              {product.price.toFixed(2)} ₺
                            </Typography>
                            {product.selectedOptions?.length > 0 && (
                              <Box sx={{ mt: 0.5, display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                {product.selectedOptions.map(optId => {
                                  const option = optionsDB.data?.[optId];
                                  return option ? (
                                    <Chip
                                      key={optId}
                                      label={option.name}
                                      size="small"
                                      variant="outlined"
                                      sx={{ 
                                        cursor: 'pointer',
                                        height: { xs: '20px', md: '24px' },
                                        '& .MuiChip-label': {
                                          fontSize: { xs: '0.65rem', md: '0.75rem' },
                                          px: { xs: 0.5, md: 0.75 }
                                        }
                                      }}
                                    />
                                  ) : null;
                                })}
                              </Box>
                            )}
                          </CardContent>
                        </Card>
                      </Grid>
                    ))}
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/* Sipariş Dialog'u */}
      <Dialog
        open={orderDialog}
        onClose={() => {
          setOrderDialog(false);
          setSelectedProduct(null);
          setQuantity(1);
          setOrderNote('');
          setSelectedOptions({});
        }}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          {selectedProduct?.name}
        </DialogTitle>
        <DialogContent dividers>
          {selectedProduct?.selectedOptions?.map(optionId => {
            const option = optionsDB.data?.[optionId];
            if (!option) return null;

            return (
              <Box key={optionId} sx={{ mb: 3 }}>
                <Typography variant="subtitle1" gutterBottom>
                  {option.name}
                </Typography>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                  {option.options.map((opt) => {
                    const isSelected = option.type === 'radio'
                      ? selectedOptions[optionId] === opt.name
                      : selectedOptions[optionId]?.includes(opt.name);

                    return (
                      <Chip
                        key={opt.name}
                        label={`${opt.name} ${opt.price > 0 ? `(+${opt.price}₺)` : ''}`}
                        onClick={() => handleOptionSelect(optionId, opt.name)}
                        color={isSelected ? 'primary' : 'default'}
                        variant={isSelected ? 'filled' : 'outlined'}
                        sx={{ cursor: 'pointer' }}
                      />
                    );
                  })}
                </Box>
              </Box>
            );
          })}

          <TextField
            fullWidth
            multiline
            rows={2}
            label="Sipariş Notu"
            value={orderNote}
            onChange={(e) => setOrderNote(e.target.value)}
            sx={{ mb: 3 }}
          />

          <Box sx={{ mb: 3 }}>
            <Typography variant="subtitle1" gutterBottom>
              Miktar
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <IconButton
                size="small"
                onClick={() => setQuantity(prev => Math.max(1, prev - 1))}
                sx={{ cursor: 'pointer' }}
              >
                <RemoveIcon />
              </IconButton>
              <Typography sx={{ minWidth: 40, textAlign: 'center' }}>
                {quantity}
              </Typography>
              <IconButton
                size="small"
                onClick={() => setQuantity(prev => prev + 1)}
                sx={{ cursor: 'pointer' }}
              >
                <AddIcon />
              </IconButton>
            </Box>
          </Box>

          <Box sx={{ mt: 3 }}>
            <Typography variant="subtitle2" color="primary">
              Ek Seçenek Tutarı: +{calculateOptionPrice(selectedOptions).toFixed(2)} ₺
            </Typography>
            <Typography variant="h6">
              Toplam: {((selectedProduct?.price || 0) + calculateOptionPrice(selectedOptions)) * quantity} ₺
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {
            setOrderDialog(false);
            setSelectedProduct(null);
            setQuantity(1);
            setOrderNote('');
            setSelectedOptions({});
          }}>
            İptal
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmitOrder}
            disabled={((selectedProduct?.price || 0) + calculateOptionPrice(selectedOptions)) * quantity === 0 || isSubmitting}
            sx={{ mt: 2 }}
          >
            {isSubmitting ? 'Gönderiliyor...' : 'Siparişi Gönder'}
          </Button>
        </DialogActions>
      </Dialog>

      <ComplimentaryDialog
        open={complimentaryDialog.open}
        onClose={() => setComplimentaryDialog({ open: false, item: null, orderIndex: -1, itemIndex: -1 })}
        item={complimentaryDialog.item}
        currentComplimentary={complimentaryDialog.item?.complimentaryQuantity || 0}
        onApply={handleComplimentaryApply}
      />

      <TableTransferDialog
        open={transferDialogOpen}
        onClose={() => setTransferDialogOpen(false)}
        tables={Object.entries(tablesDB.data || {}).map(([id, table]) => ({
          ...table,
          id,
          isOccupied: Object.values(ordersDB.data || {}).some(order => order.tableId === id)
        }))}
        selectedTable={tableId}
        onTransfer={handleTableTransfer}
      />
    </Box>
  );
};

const ComplimentaryDialog = ({ open, onClose, item, currentComplimentary = 0, onApply }) => {
  const [quantity, setQuantity] = useState(currentComplimentary);

  useEffect(() => {
    setQuantity(currentComplimentary);
  }, [currentComplimentary]);

  if (!item) return null;

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle>İkram Miktarı - {item.name}</DialogTitle>
      <DialogContent>
        <Box sx={{ pt: 2 }}>
          <Typography variant="body1" gutterBottom>
            Toplam Miktar: {item.quantity}
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 2, my: 2 }}>
            <IconButton onClick={() => setQuantity(prev => Math.max(0, prev - 1))} disabled={quantity <= 0}>
              <RemoveIcon />
            </IconButton>
            <Typography variant="h5">{quantity}</Typography>
            <IconButton onClick={() => setQuantity(prev => Math.min(item.quantity, prev + 1))} disabled={quantity >= item.quantity}>
              <AddIcon />
            </IconButton>
          </Box>
          <Typography variant="body2" color="text.secondary">
            İkram Tutarı: {((item.total / item.quantity) * quantity).toFixed(2)} ₺
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>İptal</Button>
        <Button onClick={() => onApply(quantity)} variant="contained">
          Uygula
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const TableTransferDialog = ({ open, onClose, tables, selectedTable, onTransfer }) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle>Masa Taşıma</DialogTitle>
      <DialogContent>
        <List>
          {tables
            .filter(table => table.id !== selectedTable && !table.isOccupied)
            .map((table) => (
              <ListItem
                key={table.id}
                onClick={() => onTransfer(table.id)}
                sx={{
                  mb: 1,
                  borderRadius: 1,
                  borderLeft: 3,
                  borderColor: 'success.main',
                  cursor: 'pointer',
                  '&:hover': {
                    backgroundColor: 'action.hover'
                  }
                }}
              >
                <ListItemText
                  primary={table.name}
                  secondary={`${table.type === 'large' ? '4' : '2'} Kişilik`}
                />
              </ListItem>
            ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>İptal</Button>
      </DialogActions>
    </Dialog>
  );
};

export default TableOrder; 